<template>
  <v-app class="application-main">
    <v-main>
      <div v-if="isLoading || isFailed" class="loading-wrapper">
        <div class="logo-loading">
          <img src="/assets/img/prezent_stack.svg" alt width="248px" />
        </div>
        <p v-if="isFailed" class="expired-link">
          Redirecting you to teams.prezent.ai...
        </p>
        <clip-loader
          v-if="isLoading || isFailed"
          :loading="true"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'50px'"
          class="cliploader"
        />
      </div>
      <div v-if="!isLoading && !isFailed" class="registration-wrapper">
        <div class="registration-content">
          <div class="registration-content-left">
            <TrialSignUpAdvantages
              :subscriptionType="'Prezent'"
            ></TrialSignUpAdvantages>
          </div>
          <div class="registration-content-right">
            <div class="registration-heading">Sign up to Prezent</div>
            <v-form
              class="registration-form"
              ref="registrationForm"
              v-model="isNotValid"
              lazy-validation
            >
              <v-text-field
                v-model="firstName"
                label="First name"
                :rules="formRules.firstName"
                required
                color="#fff"
                id="first"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="lastName"
                label="Last name"
                :rules="formRules.lastName"
                required
                color="#fff"
                id="last"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="email"
                :label="
                  email ? 'Work email' : 'Work email (Ex. john.doe@company.com)'
                "
                readonly
                required
                color="#fff"
                id="email"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="companyName"
                label="Company name"
                required
                readonly
                color="#fff"
                id="companyName"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <div class="terms">
                <p>
                  By clicking below, you agree to our
                  <a href="https://prezent.ai/terms-of-service/" target="_blank"
                    >Terms of Service</a
                  >
                  and
                  <a href="https://prezent.ai/privacy-policy/" target="_blank"
                    >Privacy Policy</a
                  >.
                </p>
              </div>
              <div style="text-align: left">
                <v-btn
                  @click="submit"
                  :disabled="
                    !isNotValid || !firstName || !lastName || !isValidForm
                  "
                  color="primary"
                  rounded
                  min-width="144"
                  height="50"
                  id="submit"
                  :loading="isSignupLoading"
                >
                  Continue
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { isEmojiExist } from '../components/utils';
import SuccessEnterpriseSignupModal from './components/EnterpriseSignup/SuccessEnterpriseSignupModal.vue';
import Service from '../API/enterprise-signup-api';
import TrialSignUpAdvantages from '../components/common/TrialSignUpAdvantages.vue';

export default {
  name: 'EnterpriseSignupNew',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      isNotValid: true,
      formRules: {
        firstName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.firstNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        lastName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.lastNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
      },
      isLoading: true,
      isFailed: false,
      isSignupLoading: false,
      isFocused: false,
    };
  },
  components: {
    ClipLoader,
    TrialSignUpAdvantages,
  },
  computed: {
    enterpriseSignupId() {
      return this.$route.query.id || null;
    },
    isValidForm() {
      return this.$refs.registrationForm.validate();
    },
  },
  mounted() {
    this.setDetails();
  },
  methods: {
    setDetails() {
      if (this.enterpriseSignupId) {
        Service.methods
          .getDetails(this.enterpriseSignupId)
          .then((response) => {
            this.email = response.data.email;
            this.companyName = response.data.companyname;
          })
          .catch(() => {
            this.isFailed = true;
            setTimeout(() => {
              this.$router.push('/signin', () => {});
            }, 3000);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isFailed = true;
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push('/signin', () => {});
        }, 3000);
      }
    },
    submit() {
      this.isSignupLoading = true;
      const payload = {
        id: this.enterpriseSignupId,
        email: this.email,
        firstname: this.firstName,
        lastname: this.lastName,
      };
      Service.methods
        .signup(payload)
        .then(() => {
          this.$modal.show(
            SuccessEnterpriseSignupModal,
            {},
            {
              name: 'SuccessEnterpriseSignupModal',
              width: '683px',
              height: '605px',
            },
          );
        })
        .catch(() => {})
        .finally(() => {
          this.isSignupLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-wrapper {
  margin: auto;
  // padding: 2rem;
  width: 100%;

  p {
    font-size: 14px;
  }
  .registration-content {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .registration-content-right {
      width: 50%;
      color: #fff !important;
      background-color: #1075ab;
      .registration-heading {
        font-weight: 400px;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 25px;
        padding-top: 50px;
        padding-left: 100px;
      }
      .registration-form {
        width: 500px;
        padding: 10px 30px 0px 100px;
        ::v-deep .v-input input {
          color: #fff !important;
        }
        ::v-deep .v-input__slot {
          margin-bottom: 0px !important;
        }
        ::v-deep .v-input .v-label {
          color: #fff !important;
        }
        ::v-deep .v-input__slot:before {
          border-color: #fff !important;
        }
        ::v-deep .v-input__slot::after {
          border-color: #fff !important;
        }
        ::v-deep.error--text .v-input__slot:before {
          border-color: #f70000 !important;
        }
        ::v-deep.error--text .v-input__slot::after {
          border-color: #f70000 !important;
        }
        ::v-deep.v-messages__wrapper {
          height: 25px;
        }
        ::v-deep.v-messages__message {
          position: absolute;
          color: #fff; /* Change the text color */
          font-size: 12px; /* Adjust the font size */
          font-weight: 400; /* Adjust the font size */
          background-color: #f70000; /* Add a background color */
          padding: 4px; /* Add some padding */
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .v-messages__message::before {
          content: '\f059'; /* Font Awesome "info-circle" icon or similar */
          font-family: 'Font Awesome 5 Free'; /* Make sure Font Awesome is available */
          font-weight: 900; /* Needed for solid Font Awesome icons */
          color: #fff; /* Icon color */
          margin-right: 8px; /* Space between the icon and the text */
          cursor: pointer; /* Show pointer on hover for the icon */
          display: inline-block;
          vertical-align: middle;
          position: relative;
        }
        .v-messages__message:hover::before::after {
          content: 'This field is required'; /* Tooltip text */
          position: absolute;
          top: -24px;
          left: 0;
          background-color: black;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          z-index: 9999;
        }

        /* Triangle for tooltip */
        .v-messages__message:hover::before::before {
          content: '';
          position: absolute;
          top: -8px;
          left: 10px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent black transparent;
        }
        p {
          margin-top: 20px;
        }
        button {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 30px;
          text-align: center;
          text-transform: none;
        }
        .error {
          background-color: transparent !important;
          border-color: transparent !important;
          color: red;
        }
      }
    }
    .confirmation-wrapper {
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: center;
      margin-bottom: 50px;
      text-align: center;
      width: 40%;
      .confirmation {
        .v-image {
          margin: 0 auto;
          margin-bottom: 10px;
        }
        .heading {
          font-size: 24px;
          margin-bottom: 16px;
          line-height: 36px;
        }
        .subText {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.terms {
  p {
    margin-bottom: 0px;
  }
  a {
    text-decoration: underline;
    color: #fff !important;
  }
}
.registration-content-left {
  padding: 50px 80px 0px 80px;
  width: 50%;
}

.logo {
  padding: 30px 30px 0px 40px;
  width: fit-content;
}

.logo-loading {
  padding-bottom: 20px;
}
.expired-link {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.loading-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}
</style>
