import { graphqlOperation } from 'aws-amplify';
import { GraphQLService } from './GraphQLService';
import { performSearch } from '../graphql/queries';
import { callMergeSlidesApi } from '../utils/merge-slide-helper';
import { miscConfig } from '../runtimeConfig';
import { downloadFileWithCustomName } from '../utils/common';

export class TemplateService {
  static previewSlides = [
    'agenda/deck1_0004_5',
    'insights/deck1_0132_6',
    'org_chart/deck1_0086_3',
    'financials/deck1_0060_3',
    'horizons/deck1_0155_3',
    'principles/deck1_0094_5',
    'solutions/deck1_0179_6',
    'people/deck1_0032_4',
    'quant/deck1_0006_5',
    'impact/deck1_0020_4',
  ];

  static previewSlideCount = 4;

  static async download(templateCode, companyCode, filename) {
    let slides = await this.fetchSlidesForTemplateDownload(templateCode);

    if (!slides.length) {
      slides = await this.fetchSlidesForPreview(templateCode, companyCode);
    }

    if (slides.length) {
      const mergeslidesPayload = {
        slides: slides.map((slide) => ({
          slideId:
            slide.filename.charAt(0) === '/'
              ? slide.filename.substring(1)
              : slide.filename,
        })),
        pasteOption: 'SourceFormatting',
      };

      const file = await callMergeSlidesApi(mergeslidesPayload).then((res) => {
        const mergedslide = res.data;
        return `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
      });
      await this.downloadUrl(file, filename);
    } else {
      console.log('No slides found!');
    }
  }

  static async fetchSlidesForTemplateDownload(templateCode) {
    const slides = await this.fetchFromSolr({
      andprefs: {
        theme: templateCode,
      },
      notprefs: {
        source: 'prezent',
      },
      is_recommender_search: false,
      limit: 30,
    });

    return slides;
  }

  static async fetchSlidesForPreview(templateCode, companyCode) {
    const slideIds = this.previewSlides.map((slide) => {
      const [idea, deck] = slide.split('/');
      return `${companyCode}_${idea}_${templateCode}_${deck}_${templateCode}`;
    });
    const result = await this.fetchFromSolr({
      andprefs: {
        theme: templateCode,
        id: slideIds,
        is_parent_slide: [true, false],
      },
      limit: 10,
      is_recommender_search: false,
    });
    const slides = this.sortPreviewSlides(result).slice(
      0,
      this.previewSlideCount,
    );

    if (slides.length < this.previewSlideCount) {
      const resultWithoutCaregories = await this.fetchFromSolr({
        andprefs: {
          theme: templateCode,
          has_cartoon: ['yes', 'no'],
          is_know: ['yes', 'no'],
        },
        notprefs: {
          category: ['prezautoconversion', 'prezfingerprinttest'],
        },
        limit: this.previewSlideCount - slides.length,
        is_recommender_search: false,
      });
      slides.push(...resultWithoutCaregories);
    }

    return slides;
  }

  static sortPreviewSlides(slides) {
    const order = Object.fromEntries(
      this.previewSlides.map((slideData, index) => [
        slideData.split('/')[0],
        index,
      ]),
    );
    const getSlideId = (uniqueID) => {
      // check if slide id exists in unique id
      for (const slideId in order) {
        if (uniqueID.includes(slideId)) {
          return slideId;
        }
      }
      return null; // In case no id is found
    };
    return slides.sort((aStr, bStr) => {
      const slideA = getSlideId(aStr?.unique_id);
      const slideB = getSlideId(bStr?.unique_id);
      return order[slideA] - order[slideB];
    });
  }

  static async fetchFromSolr(query) {
    const result = await GraphQLService.requestWithToken(
      graphqlOperation(performSearch, {
        parameters: JSON.stringify(query),
      }),
    );
    const slides = JSON.parse(result.data.performSearch);

    return slides?.body?.payloads || [];
  }

  static downloadUrl(url, filename) {
    return new Promise((resolve, reject) => {
      const progressCallback = (progress) => {
        if (!progress) {
          resolve();
        }
      };
      const errorCallback = () => {
        reject();
      };
      downloadFileWithCustomName(
        url,
        filename,
        progressCallback,
        true,
        errorCallback,
      );
    });
  }
}
