var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"application-main"},[_c('v-main',[(!_vm.isExistingUser)?_c('div',{staticClass:"registration-wrapper"},[_c('div',{staticClass:"registration-content"},[(!_vm.signedUp)?_c('div',{staticClass:"registration-content-left"},[_c('TrialSignUpAdvantages',{attrs:{"subscriptionType":'Prezent Enterprise'}})],1):_vm._e(),(!_vm.signedUp)?_c('div',{staticClass:"registration-content-right"},[_c('div',{staticClass:"registration-heading"},[_vm._v(" Sign up to Prezent Enterprise ")]),_c('v-form',{ref:"registrationForm",staticClass:"registration-form",attrs:{"lazy-validation":""},model:{value:(_vm.isNotValid),callback:function ($$v) {_vm.isNotValid=$$v},expression:"isNotValid"}},[_c('v-text-field',{attrs:{"label":"First name","rules":_vm.formRules.firstName,"disabled":_vm.isReadonlyfirstName,"required":"","color":"#fff","id":"first"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{attrs:{"label":"Last name","rules":_vm.formRules.lastName,"disabled":_vm.isReadonlylastName,"required":"","color":"#fff","id":"last"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('v-text-field',{attrs:{"label":_vm.isFocused || _vm.email
                  ? 'Work email'
                  : 'Work email (Ex. john.doe@company.com)',"rules":_vm.formRules.email,"disabled":_vm.isReadonlyemail,"required":"","color":"#fff","id":"email"},on:{"focus":function($event){_vm.isFocused = true},"blur":_vm.handleEmailChange},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Company name","rules":_vm.formRules.companyName,"required":"","color":"#fff","id":"companyName"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"14px"},attrs:{"color":"#fff"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v(" "+_vm._s(message))])]}}],null,false,440903354),model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('div',{staticClass:"terms"},[_c('p',[_vm._v(" By clicking below, you agree to our "),_c('a',{attrs:{"href":"https://prezent.ai/terms-of-service/","target":"_blank"}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://prezent.ai/privacy-policy/","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(". ")])]),_c('div',{staticStyle:{"text-align":"left"}},[_c('v-btn',{attrs:{"disabled":!_vm.isNotValid ||
                  !_vm.firstName ||
                  !_vm.lastName ||
                  !_vm.isEmailValid ||
                  !_vm.companyName,"color":"primary","rounded":"","min-width":"144","height":"50","id":"submit","loading":_vm.isSignupLoading},on:{"click":_vm.submit}},[_vm._v(" Continue ")])],1)],1)],1):_c('div',{staticClass:"confirmation-wrapper"},[_c('div',{staticClass:"confirmation"},[_c('v-img',{attrs:{"src":"/assets/img/feather_check-circle.svg","max-width":"60px","max-height":"60px","alt":""}}),_c('p',{staticClass:"heading"},[_vm._v(_vm._s(_vm.signUpHeading))]),_c('div',{staticClass:"subText",domProps:{"innerHTML":_vm._s(_vm.returnMarked(_vm.getMessage))}})],1)])])]):_c('Redirect')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }