import createPersistedState from 'vuex-persistedstate';

const initialState = {
  isDirty: false,
  showDialog: false,
  shouldReturnToSlide: false,
  searchTerm: '',
  source: '',
};
export const replaceImageStore = {
  namespaced: true,
  state: initialState,
  getters: {
    getIsDirty: (state) => (state ? state.isDirty : initialState.isDirty),
    getSource: (state) => state.source,
    getShowDialog: (state) =>
      state ? state.showDialog : initialState.showDialog,
    getShouldReturnToSlide: (state) =>
      state ? state.shouldReturnToSlide : initialState.shouldReturnToSlide,
    getSearchTerm: (state) =>
      state
        ? {
            searchTerm: state.searchTerm,
            source: state.source,
          }
        : {
            searchTerm: initialState.searchTerm,
            source: initialState.source,
          },
  },
  mutations: {
    OPEN_DIALOG(state) {
      state.showDialog = true;
    },
    CLOSE_DIALOG(state) {
      state.showDialog = false;
    },
    RESET(state) {
      if (state) {
        state.isDirty = false;
      }
    },
    RESET_RETURN(state) {
      if (state) {
        state.shouldReturnToSlide = false;
      }
    },
    SET_SOURCE(state, val) {
      state.source = val;
    },
    MAKE_DIRTY(state) {
      state.isDirty = true;
    },
    SET_RETURN_TO_SLIDE(state) {
      state.shouldReturnToSlide = true;
    },
    SET_SEARCH_DATA(state, searchData) {
      const { searchTerm, source } = searchData;
      state.searchTerm = searchTerm;
      state.source = source;
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    resetReturn({ commit }) {
      commit('RESET_RETURN');
    },
    makeDirty({ commit }) {
      commit('MAKE_DIRTY');
    },
    openDialog({ commit }, shouldReturnToSlide = false) {
      commit('OPEN_DIALOG');
      if (shouldReturnToSlide) commit('SET_RETURN_TO_SLIDE');
    },
    closeDialog({ commit }) {
      commit('CLOSE_DIALOG');
    },
    setSource({commit}, data) {
      commit('SET_SOURCE', data);
    },
    setImageSearchData({ commit }, searchData) {
      commit('SET_SEARCH_DATA', searchData);
    },
  },
  persist: createPersistedState({
    key: 'vuex_replaceImageStore',
    paths: [],
  }),
};
