import usersApi from '../../API/users-api';

const state = {
  profile: {},
  redesignLayoutTab: 0,
};

const mutations = {
  SET_PROFILE_BASICS(state, content) {
    state.profile = {
      ...state.profile,
      basics: {
        ...content,
      },
    };
  },
  SET_PROFILE_TOPTIPS(state, content) {
    state.profile = {
      ...state.profile,
      topTips: [...content],
    };
  },
  SET_REDESIGN_LAYOUT_TAB(state, val) {
    state.redesignLayoutTab = val;
  },
};

const actions = {
  setProfileBasics: async ({ commit, rootState }, content) => {
    rootState.users.currentUser.firstName = content.firstName;
    rootState.users.currentUser.lastName = content.lastName;
    rootState.users.currentUser.company = content.company;
    rootState.users.currentUser.jobTitle = content.jobTitle;
    rootState.users.currentUser.organizationID = content.function;
    usersApi.methods.updateUserProfile(rootState.users.currentUser).then(
      (value) => {
        console.log(value);
      },
      (reason) => {
        console.error(reason); // Error!
      },
    );

    commit('SET_PROFILE_BASICS', content);
  },
  setProfileTopTips: async ({ commit }, content) => {
    commit('SET_PROFILE_TOPTIPS', content);
  },
  setRedesignLayoutTab({ commit }, val) {
    commit('SET_REDESIGN_LAYOUT_TAB', val);
  },
};

const getters = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
