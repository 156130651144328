<template>
  <div class="template-designer-wrap">
    <div
      class="final-transition-container"
      v-if="showFinalTransition"
      :class="showSuccessTransition ? 'success-slide-animate' : ''"
    >
      <p
        class="loading-text"
        :class="showSuccessTransition ? 'smooth-text' : ''"
      >
        {{
          showSuccessTransition
            ? $t('templateDesign.successCustomization')
            : $t('templateDesign.loadingCustomization')
        }}
      </p>

      <div class="video-container">
        <video
          autoplay
          muted
          playsinline
          :loop="loopTransition"
          ref="video"
          @ended="onEnd()"
        >
          <source
            :src="'/assets/img/templateDesign/slide-transition.mp4'"
            type="video/mp4"
          />
        </video>
      </div>
      <p class="redirect-counter" v-if="isAddDTRoute && showSuccessTransition">
        {{
          $t('templateDesign.returnToProfile', {
            counter: secondsCounter,
          })
        }}
      </p>
    </div>
    <div class="loader-container" v-else-if="loading">
      <clip-loader
        :loading="loading"
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>

    <div v-else class="template-design-container" id="add-slide-transition">
      <div class="progress-container" v-if="showProgressContainer">
        <div class="title-container">
          <div
            v-if="showBackBtn"
            class="back-button"
            @click="goBack()"
            v-html="$t('templateDesign.backBtn')"
          ></div>
          <div class="progress-title">
            {{ $t('templateDesign.progressTitle') }}
          </div>
          <div
            v-if="isAddDTRoute"
            class="exit-template-button"
            @click="handleExitTemplate()"
            v-html="$t('templateDesign.uploadTemplate.exitTemplate')"
          ></div>
        </div>
        <div class="progress-wrapper">
          <v-progress-linear :value="(stepper + 1) * 20" :height="8" rounded />
        </div>
      </div>
      <ViewDesignerTemplates
        v-if="isTrialAdminWithDT && showDesignerTemplates"
        :designerTemplates="designerTemplates"
        @onAddNow="handleAddNow"
        @onNext="handleViewDTNext"
      />
      <UploadTemplate
        v-else-if="stepper === 0"
        :fileData="uploadedFileData"
        :origin="origin"
        @handleNext="handleUploadNext"
        @setFileData="setFileData"
        @backToStd="backToStd"
        @handleTransition="handleTransition"
      ></UploadTemplate>
      <template v-else>
        <div v-for="(item, i) in filterMap" :key="i">
          <TemplateAttributeSelector
            v-if="i === stepper - 1"
            :origin="origin"
            :placement="getPlacement"
            :attributeData="item"
            @handleSelected="onSelect"
          ></TemplateAttributeSelector>
        </div>
      </template>
    </div>
    <div class="confetti-container" v-if="showConfetti">
      <img
        class="confetti-img slide-down"
        :src="'/assets/img/templateDesign/confetti.png'"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import TemplateAttributeSelector from './TemplateAttributeSelector.vue';
import UploadTemplate from './UploadTemplate.vue';
import { TemplateDesignerService } from './TemplateDesignerService';
import ViewDesignerTemplates from './ViewDesignerTemplates.vue';
import usersApi from '../../../API/users-api';
import { getFileURL } from '@/utils/calyrex';
import { trackTemplateDesignerEvents } from '../../../components/common/Analytics/TemplateDesignerEvents.js';
import {
  TD_FLOW,
  TD_PLACEMENT,
  TD_PROFILE,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_DESIGNER_UPLOAD,
  TD_DESIGNER_ALIGNED,
  TD_DESIGNER_ADHERED,
  TD_DESIGNER_IMAGES,
  TD_AFTER_LOGIN,
  TD_TEMPLATE,
  NA,
} from '../../../components/common/Analytics/MatomoTrackingDataHelper.js';

export default {
  name: 'TemplateDesignContainer',
  data() {
    return {
      error: '',
      uploadedFileData: {},
      showFinalTransition: false,
      loopTransition: true,
      showSuccessTransition: false,
      showConfetti: false,
      filterPayload: {},
      previewDeck: null,
      previewDeckData: null,
      showTransition: false,
      showDesignerTemplates: true,
      stepper: 0,
      secondsCounter: 5,
      filterMap: [
        {
          filter: 'color_scheme_filter',
          text: this.$t('templateDesign.templateAttributes.colorSchemes.title'),
          subText: this.$t(
            'templateDesign.templateAttributes.colorSchemes.subText',
          ),
          img: {
            first_option: '',
            second_option: '',
          },
          selected: null,
        },
        {
          filter: 'font_color_filter',
          text: this.$t('templateDesign.templateAttributes.fontColors.title'),
          subText: this.$t(
            'templateDesign.templateAttributes.fontColors.subText',
          ),
          img: {
            first_option: '',
            second_option: '',
          },
          selected: null,
        },
        {
          filter: 'brand_images_filter',
          text: this.$t('templateDesign.templateAttributes.brandImages.title'),
          subText: this.$t(
            'templateDesign.templateAttributes.brandImages.subText',
          ),
          img: {
            first_option: '/assets/img/templateDesign/healthcare-collage.png',
            second_option:
              '/assets/img/templateDesign/non-healthcare-collage.png',
          },
          selected: null,
        },
      ],
      designerTemplates: [],
      loading: false,
      selectedDesignerTheme: {},
      matomoPlacementMap: {
        0: TD_DESIGNER_UPLOAD,
        1: TD_DESIGNER_ALIGNED,
        2: TD_DESIGNER_ADHERED,
        3: TD_DESIGNER_IMAGES,
      },
    };
  },
  props: {
    origin: {
      type: String,
      required: true,
      default: 'Signup',
    },
  },
  mounted() {
    if (this.isAddDTRoute) {
      this.showDesignerTemplates = false;
    } else {
      this.loading = true;
      this.fetchDT();
    }
  },
  methods: {
    ...mapActions('users', ['setCurrentTheme', 'setCurrentUser']),
    backToStd(error) {
      if (error) {
        this.$emit('backToStd', 'standard');
      }
    },
    handleAddNow() {
      this.showDesignerTemplates = false;
      this.stepper = 0;
    },
    startProfileRedirectCounter() {
      this.secondsCounter = 5;
      const countDownTimer = setInterval(() => {
        if (this.secondsCounter <= 1) {
          this.showConfetti = false;
          this.showFinalTransition = false;
          this.showSuccessTransition = false;
          clearInterval(countDownTimer);
          this.$router.push('/profile?tab=templates', () => {});
        } else {
          this.secondsCounter -= 1;
        }
      }, 1000);
    },
    handleExitTemplate() {
      trackTemplateDesignerEvents.designerExit(this.currentUser, {
        [TD_TEMPLATE]: NA,
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: TD_PROFILE,
        [TD_PLACEMENT]: this.getPlacement,
      });
      this.$router.push('/profile?tab=templates', () => {});
    },
    async handleViewDTNext(selectedTheme) {
      this.selectedDesignerTheme = selectedTheme;
      this.showDesignerTemplates = false;
      this.stepper = 4;
      this.showFinalTransition = true;
      // API Call to set selected template
      try {
        await usersApi.methods
          .updateUserProfile({ defaultTheme: this.selectedDesignerTheme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = { ...userInfo.user, ...updatedUserDetail };
            userInfo.theme = this.selectedDesignerTheme;
            await this.setCurrentUser(userInfo);
          });
        this.setCurrentTheme(this.selectedDesignerTheme);
        this.previewDeckData = {
          fingerprint: this.selectedDesignerTheme?.fingerprintPreferences,
        };
        this.handleImageFetch();
      } catch (err) {
        this.error = 'SELECTED_DT_ERR';
        this.showFinalTransition = true;
        this.handleEndTransition();
        console.error(err);
      }
    },
    handleTransition(showTransition) {
      this.showTransition = showTransition;
    },
    handleUploadNext(data) {
      trackTemplateDesignerEvents.designerNextFlow(this.currentUser, {
        [TD_TEMPLATE]: NA,
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.isAddDTRoute
          ? TD_PROFILE
          : TD_AFTER_LOGIN,
        [TD_PLACEMENT]: this.getPlacement,
      });
      if (Object.keys(data).length) {
        this.filterMap.map((item) => {
          if (data?.[item.filter]) {
            item.img = {
              first_option: data?.[item.filter]?.first_option,
              second_option: data?.[item.filter]?.second_option,
            };
          }
          return item;
        });
      }
      this.goNext();
    },
    onSelect(selected) {
      this.toogleAnimation('add');
      if (this.stepper < 4) {
        trackTemplateDesignerEvents.designerNextFlow(this.currentUser, {
          [TD_TEMPLATE]: NA,
          [TD_COMMON_COLUMN_NAME]: TD_FLOW,
          [TD_COMMON_COLUMN_VALUE]: this.isAddDTRoute
            ? TD_PROFILE
            : TD_AFTER_LOGIN,
          [TD_PLACEMENT]: this.getPlacement,
        });
        this.filterMap[this.stepper - 1].selected = selected;
      }
      setTimeout(() => {
        this.goNext();
        this.toogleAnimation('remove');
        if (this.stepper > 3) {
          this.onTemplateComplete();
        }
      }, 1400);
    },
    goNext() {
      this.stepper += 1;
    },
    // On End of the video
    onEnd() {
      if (!this.loopTransition) {
        this.showSuccessTransition = true;
        this.showConfetti = true;
        if (this.isAddDTRoute) {
          this.startProfileRedirectCounter();
        } else {
          setTimeout(() => {
            // To change animation time, modify this settimeout, slide-down & slide-out
            this.showConfetti = false;
            this.showFinalTransition = false;
            this.showSuccessTransition = false;
            if (this.error === 'JOB_ERR' || this.error === 'SELECTED_DT_ERR') {
              this.$emit('handleSubmitErr', this.error);
            } else {
              this.$emit('onTemplateComplete', this.previewDeck);
            }
          }, 6000);
        }
      }
    },
    goBack() {
      if (this.stepper === 0) {
        this.fileData = {};
        this.$emit('onBack', 'TMP_BK');
      } else {
        this.toogleAnimation('add');
        setTimeout(() => {
          this.stepper -= 1;
          this.toogleAnimation('remove');
        }, 1000);
      }
    },
    toogleAnimation(type) {
      const td = document.getElementById('add-slide-transition');
      if (td && type === 'add') {
        td.classList.add('slide-animate');
      } else if (td && type === 'remove') {
        td.classList.remove('slide-animate');
      }
    },
    setFileData(fileData) {
      this.uploadedFileData = fileData;
      if (!Object.keys(fileData).length) {
        this.filterMap.map((item) => {
          item.selected = null;
          item.img = {
            first_option: '',
            second_option: '',
          };
          return item;
        });
      }
    },
    // fetch all fp images and check if they are loading
    async handleImageFetch() {
      const fp = this.previewDeckData?.fingerprint;
      const fpUrls = [];
      /* TODO: Fetch url and check if it loads in one loop 
            (currently all are fetched and then checked for loading) */
      for (let index = 0; index < fp.length; index++) {
        const image = await getFileURL(
          this.currentUser.user.cognitoID,
          fp[index].filepath,
          this.currentUser.userIp,
          'IOCUpload',
        );
        fpUrls.push(image);
      }
      this.checkAllImagesLoad(fpUrls)
        .then((allImagesAreLoading) => {
          if (allImagesAreLoading) {
            this.previewDeck = { fingerprint: fpUrls };
          } else {
            this.previewDeck = null;
          }
          this.handleEndTransition();
        })
        .catch((err) => {
          this.previewDeck = null;
          this.handleEndTransition();
          console.log('Images Failed to load', err);
        });
    },
    async checkImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(); // Resolve if image loads successfully
        img.onerror = () => reject(); // Reject if image fails to load
        img.src = url;
      });
    },
    async checkAllImagesLoad(images) {
      const totalImages = images.length;
      let loadedCount = 0;
      for (const image of images) {
        try {
          // Check image
          if (image) {
            await this.checkImage(image);
            loadedCount++;
          }
        } catch {
          return false; // If any image fails to load, return false
        }
      }
      return loadedCount === totalImages; // Return true only if all images are loaded successfully
    },
    async onTemplateComplete() {
      this.showFinalTransition = true;
      this.templateDesignerService
        .submitTemplateConversion(this.getSubmitPayload())
        .then((resp) => {
          if (resp && resp.status === 'success') {
            // if (
            //   this.currentUser.user.showTemplateDesignerFlow === 0 &&
            //   this.currentUser.company.isNonBranded
            // ) {
            //   this.loopTransition = false;
            //   this.$refs.video.currentTime = this.$refs.video.duration;
            // } else {
            this.templateDesignerService
              .fetchPreviewDeck(this.uploadedFileData?.uploadId, (interval) => {
                this.templateDesignerService.intervals.push(interval);
              })
              .then((response) => {
                if (response.status === 'success') {
                  this.previewDeckData = response?.data;
                  if (this.isAddDTRoute) {
                    this.handleEndTransition(); // no need to fetch fp image urls
                  } else {
                    this.handleImageFetch();
                  }
                }
              })
              .catch(() => {
                this.error = 'JOB_ERR';
                this.handleEndTransition();
              });
            // }
          }
        })
        .catch(() => {
          this.error = 'JOB_ERR';
          this.handleEndTransition();
        });
    },
    getSubmitPayload() {
      const payload = { upload_id: this.uploadedFileData?.uploadId };
      this.filterMap.forEach((item) => {
        if (item.filter === 'brand_images_filter') {
          payload[item.filter] =
            item.selected === 'first_option' ? 'Healthcare' : 'Non-healthcare';
        } else {
          payload[item.filter] = item.selected;
        }
      });
      return payload;
    },
    async fetchDT() {
      this.templateDesignerService
        .fetchDesignerTemplates()
        .then((response) => {
          this.designerTemplates = [...response];
          if (this.designerTemplates.length === 0) {
            this.showDesignerTemplates = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.showDesignerTemplates = false;
          this.loading = false;
        });
    },
    // This will end the video loop
    handleEndTransition() {
      this.loopTransition = false;
      this.$refs.video.currentTime = this.$refs?.video?.duration;
    },
  },

  beforeDestroy() {
    this.templateDesignerService.cancel();
  },
  computed: {
    ...mapState('users', ['currentUser']),
    templateDesignerService() {
      return new TemplateDesignerService();
    },
    showBackBtn() {
      return !(this.isAddDTRoute && this.stepper === 0); // should not be shown through add profile on first screen
    },
    isAddDTRoute() {
      return this.$route.path.includes('addDesignerTemplate');
    },
    isTrialAdminWithDT() {
      return this.designerTemplates.length;
    },
    showProgressContainer() {
      return (
        this.stepper <= 3 && !this.showTransition && !this.showDesignerTemplates // should be shown in non view designer template screens
      );
    },
    getPlacement() {
      return this.matomoPlacementMap[this.stepper];
    },
  },
  components: {
    UploadTemplate,
    TemplateAttributeSelector,
    ViewDesignerTemplates,
    ClipLoader,
  },
};
</script>

<style lang="scss" scoped>
@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  50% {
    transform: translateY(70%);
  }
  75% {
    opacity: 1;
    transform: translateY(90%);
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-design-container {
  display: flex;
  flex-direction: column;

  &.slide-animate {
    animation: 1s ease-out slide-out;
    transition: all 0.3s ease-out;
    animation-delay: 500ms;
  }
  .progress-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .title-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      .back-button {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        position: absolute;
        right: calc(100% + 200px);
        width: 54px;
        cursor: pointer;
        color: #29b6f6;
        display: flex;
        align-items: center;
      }
      .progress-title {
        color: #000000;
        font-family: 'Lato';
        line-height: 29px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
      }
      .exit-template-button {
        font-family: 'Lato';
        font-style: normal;
        font-size: 16px;
        position: absolute;
        left: calc(100% + 224px);
        cursor: pointer;
        color: #29b6f6;
        display: flex;
        align-items: center;
        width: 184px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
      }
    }
    .progress-wrapper {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }
}

.slide-down {
  animation: 6.2s ease-out slide-down;
  //animation-delay: 200ms;
}
.confetti-container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  pointer-events: none;
  .confetti-img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.final-transition-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  width: 100%;

  .loading-text {
    font-family: Lato;
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 32px;
  }

  .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      height: 100%;
      aspect-ratio: 16/9;
      width: 750px;
    }
  }
  .redirect-counter {
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
  }
}
.hide-success-transition {
  visibility: hidden;
}
.success-slide-animate {
  animation: 1s ease-out slide-out;
  transition: all 0.3s ease-out;
  animation-delay: 5500ms;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.smooth-text {
  animation: fadeInAnimation ease-in 1s;
}
</style>
