<template>
  <div class="attribute-content">
    <div class="attribute-content-header">
      <div class="header" v-html="returnMarked(title)"></div>
      <div class="sub-header">
        <p v-html="returnMarked(subText)"></p>
      </div>
    </div>
    <div class="images-wrapper">
      <TemplateCard
        :src="firstImgSource"
        :selected="firstCardSelected"
        :cardData="firstCardData"
        @selectedCardData="handleSelectedCardData"
      />
      <TemplateCard
        :src="secondImgSource"
        :selected="secondCardSelected"
        :cardData="secondCardData"
        @selectedCardData="handleSelectedCardData"
      />
    </div>
    <div class="get-in-touch-container">
      <p class="dont-worry">
        {{ $t('templateDesign.dontWorry') }}
      </p>
      <p>
        {{ $t('templateDesign.furtherCustomization') }}
        <a @click="getInTouch">{{ $t('templateDesign.getInTouch') }}</a>
        {{ $t('templateDesign.designTeam') }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { mapState } from 'vuex';
import TemplateCard from './TemplateCard.vue';
import ContactSoonModal from '../../../components/common/ContactSoonModal.vue';
import { sendEmail } from '../../../utils/api-helper';
import { trackTemplateDesignerEvents } from '../../../components/common/Analytics/TemplateDesignerEvents.js';
import {
  TD_FLOW,
  TD_PLACEMENT,
  TD_PROFILE,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_AFTER_LOGIN,
  TD_TEMPLATE,
  NA,
} from '../../../components/common/Analytics/MatomoTrackingDataHelper.js';

export default {
  name: 'TemplateAttributeSelector',
  data() {
    return {
      selectedTemplateData: {},
      firstCardSelected: null,
      secondCardSelected: null,
      firstImgSource: '/assets/img/slides/placeholder-slide.svg',
      secondImgSource: '/assets/img/slides/placeholder-slide.svg',
      firstCardData: 'first_option',
      secondCardData: 'second_option',
      title: '',
      subText: '',
      selectedText: '',
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  props: {
    attributeData: {},
    origin: {
      type: String,
      required: true,
      default: 'Signup',
    },
    placement: {
      type: String,
    },
  },
  methods: {
    returnMarked(content) {
      return DOMPurify.sanitize(
        `<div class="marked-container">${marked(content)}</div>`,
      );
    },
    handleSelectedCardData(cardData) {
      const templateData = {};
      this.selectedText = cardData;
      this.setSelectedCard();
      templateData[this.attributeData.filter] = cardData;
      this.$emit('handleSelected', cardData);
      this.$emit('handleNext', templateData);
    },
    getInTouch() {
      trackTemplateDesignerEvents.designerGetInTouch(this.currentUser, {
        [TD_TEMPLATE]: NA,
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]:
          this.origin === 'Profile' ? TD_PROFILE : TD_AFTER_LOGIN,
        [TD_PLACEMENT]: this.placement,
      });
      try {
        const params = {
          type: 'template-designer-request',
          meta: {
            firstName: `${this.currentUser?.user?.firstName}`,
            trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    setSelectedCard() {
      this.firstCardSelected = this.selectedText === this.firstCardData;
      this.secondCardSelected = this.selectedText === this.secondCardData;
    },
  },
  mounted() {
    this.firstImgSource = this.attributeData.img?.first_option;
    this.secondImgSource = this.attributeData.img?.second_option;
    this.title = this.attributeData.text;
    this.subText = this.attributeData.subText;
    this.selectedText = this.attributeData?.selected;
    if (this.selectedText) {
      this.setSelectedCard();
    }
  },
  components: { TemplateCard },
};
</script>

<style lang="scss" scoped>
.attribute-content {
  display: flex;
  flex-direction: column;

  .attribute-content-header {
    margin-bottom: 40px;
    font-size: 20px;
    .header {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      line-height: 52px;
      margin-bottom: 15px;
    }
    .sub-header {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
    }
  }
  .images-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    gap: 7rem;
  }
}
.get-in-touch-container {
  padding-top: 20px;
  margin-top: 20px;
  .dont-worry {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
