import axios from 'axios';
import { mapActions } from 'vuex';
import { checkSSOUser, getSuggestionsTotalCount } from './api-helper';
import { SSOConfig } from '@/runtimeConfig';
import { defaultFetchCount } from './constants';
import API2 from './api';

mapActions('users', ['setDownloadBlockForUser']);

export const capitalizeFirstLetter = (name) => {
  if (name && name.charAt(0)) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  return name;
};

export const getFilename = (item) =>
  item && item.filename ? item.filename : '';

export const transformId = (id) => id.toString().replaceAll('_', '-');

// template message constants
export const TRIAL_ADMIN_LICENSE_UPGRADED = 'TRIAL_ADMIN_LICENSE_UPGRADED';

export const getFullName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
      lastName,
    )}`;
  }
  return `${capitalizeFirstLetter(firstName)}`;
};

export const getInitials = (firstName, lastName) => {
  if (firstName && lastName)
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

  return firstName ? firstName.charAt(0).toUpperCase() : '';
};

export const formatUserForAudienceItem = (user) => {
  if (!user) return null;
  let fullName;

  const id = user.lastName.toLowerCase() === 'group' ? 'Group' : user.id;

  if (user.fullName) {
    if (id === 'Group' && user.fullName.includes('(Private)')) {
      fullName = `${user.firstName} (Private)`;
    } else {
      fullName = user.fullName;
    }
  } else {
    fullName = getFullName(user.firstName, user.lastName);
  }

  const formattedUser = {
    ...user,
    id,
    fullName,
    audienceCompanyDisplayName: user.companyName,
  };
  return formattedUser;
};

export const replacePrivateInParenthesis = (name) => {
  if (name) {
    return name.replace('(Private)', '');
  }
  return name;
};

const getLastName = (lastName) => replacePrivateInParenthesis(lastName);

export const getIsGroup = (user) => {
  const { lastName } = user;
  return (
    lastName &&
    replacePrivateInParenthesis(lastName).trim().toLowerCase() === 'group'
  );
};

export const frameUserPreferencesResponse = (preferences) => {
  const userPrefreneces = { fingerprintResponse: [] };
  [
    'question0',
    'question1',
    'question2',
    'question3',
    'question4',
    'question5',
  ].forEach((question) => {
    userPrefreneces.fingerprintResponse.push(
      `${question},${
        preferences[`${question}`] === 1 ? 'leftImage' : 'rightImage'
      }`,
    );
  });
  return userPrefreneces;
};

export const getIsDifferentCompany = (user, currentUser) => {
  const {
    company: { name, displayName },
  } = currentUser;
  const { companyName } = user;
  if (!(name || displayName) || !companyName) {
    return false;
  }
  const lowerCaseCompanyName = companyName?.toLowerCase();
  const isCompanyNameMatches = name?.toLowerCase() === lowerCaseCompanyName;
  const isCompanyDisplayNameMatches =
    displayName?.toLowerCase() === lowerCaseCompanyName;
  return !(isCompanyNameMatches || isCompanyDisplayNameMatches);
};

export const isGeneratedEmail = (item) => {
  if (item) {
    return item.emailEntered === false;
  }
  return false;
};

export const getCommonPrivateAudienceData = (user, currentUser) => {
  const { companyName: audienceCompanyName, firstName, lastName } = user;
  const isDifferentCompany = getIsDifferentCompany(user, currentUser);
  const isEmailGenerated = isGeneratedEmail(user);
  const isGroup = getIsGroup(user);
  const companyName = isDifferentCompany
    ? capitalizeFirstLetter(audienceCompanyName)
    : null;
  const computedLastName = isGroup ? '' : getLastName(lastName);
  return {
    isDifferentCompany,
    isGeneratedEmail: isEmailGenerated,
    companyName,
    isGroup,
    fullName: `${getFullName(firstName, computedLastName)}`,
    lastName: capitalizeFirstLetter(computedLastName),
    audienceCompanyDisplayName: audienceCompanyName,
  };
};

export const formatUserForPrivateAudienceItem = (user, currentUser) => {
  if (!user || !currentUser) return null;
  const { shared } = user;
  const {
    isDifferentCompany,
    companyName,
    isGroup,
    isGeneratedEmail: isEmailGenerated,
    fullName,
    lastName,
    audienceCompanyDisplayName,
  } = getCommonPrivateAudienceData(user, currentUser);
  const formattedUser = {
    ...user,
    fullName,
    lastName,
    isDifferentCompany,
    isGeneratedEmail: isEmailGenerated,
    isPrivate: true,
    isShared: shared,
    isGroup,
    companyName,
    audienceCompanyDisplayName,
  };
  return formattedUser;
};

export const formatUserForSharedAudienceItem = (user, currentUser) => {
  if (!user || !currentUser) return null;
  const { creator } = user;
  const {
    isDifferentCompany,
    companyName,
    isGroup,
    isGeneratedEmail: isEmailGenerated,
    fullName,
    lastName,
    audienceCompanyDisplayName,
  } = getCommonPrivateAudienceData(user, currentUser);
  const formattedUser = {
    ...user,
    fullName,
    lastName,
    isGroup,
    creator,
    isDifferentCompany,
    isGeneratedEmail: isEmailGenerated,
    companyName,
    shared: true,
    audienceCompanyDisplayName,
  };
  return formattedUser;
};

export const getEmailOfHybridSearchUser = (emailString) => {
  const occurancies = [...emailString.matchAll(/@/g)].length;

  // if UUID
  if (occurancies === 0) return '';

  // if valid email
  if (occurancies === 1) return emailString;

  // extract first valid email if emailString is manipulated from api
  const regexp =
    /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/g;
  const emailArr = [...emailString.matchAll(regexp)];

  if (emailArr.length > 0) return emailArr[0][0];

  // return back if can't extract valid email
  return '';
};

export const intToHex = (nr) => nr.toString(16).padStart(2, '0');

export const getChoice = (fingerprint) => {
  let choice = { visual: 6.0, data: 5.0 };
  if (!fingerprint) return choice;
  switch (fingerprint.toLowerCase()) {
    case 'director':
      choice = { visual: 6.0, data: 5.0 };
      return choice;

    case 'performer':
      choice = { visual: 6.0, data: 0.0 };
      return choice;

    case 'navigator':
      choice = { visual: 6.0, data: 0.0 };
      return choice;

    case 'surgeon':
      choice = { visual: 0.0, data: 5.0 };
      return choice;

    case 'architect':
      choice = { visual: 0.0, data: 5.0 };
      return choice;

    case 'scholar':
      choice = { visual: 6.0, data: 5.0 };
      return choice;

    case 'scientist':
      choice = { visual: 0.0, data: 5.0 };
      return choice;

    default:
      return choice;
  }
};

export const cacheUser = (email) => {
  localStorage.setItem('prezent.lastLoginEmail', email);
};

export const clearCachedUser = () => {
  localStorage.removeItem('prezent.lastLoginEmail');
};

export const getCachedUser = () => {
  const emailToUse = localStorage.getItem('prezent.lastLoginEmail');
  if (emailToUse && emailToUse.includes('.noreply')) {
    return '';
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailToUse && re.test(emailToUse)) {
    return emailToUse;
  }
  return '';
};

export const getRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
};

/**
 * Below function tries to login the user through SSO if possible, else returns { ssoUser: false }
 * If the user's email matches with an enterprise and not registered with Prezent.ai, then returns { enterpriseSignupEmailSent: true }
 * @param {string} email
 * @param {boolean} shouldCheckDomain
 * @returns {{ ssoUser: boolean; enterpriseSignupEmailSent: boolean; }}
 */
export const trySSOLoginAndRedirect = async (email, shouldCheckDomain) => {
  const emailToUse = email || getCachedUser();
  // Handle special case required for product team
  if (emailToUse && emailToUse.includes('.noreply')) {
    return { ssoUser: false };
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let proceed = false;
  let ssoObject = {};
  if (emailToUse && re.test(emailToUse)) {
    // Check if user is sso enabled
    ssoObject = await checkSSOUser(emailToUse);
    proceed = ssoObject.data.ssoUser;
  } else if (shouldCheckDomain) {
    // Check if domain is sso enabled
    ssoObject = await checkSSOUser(false, shouldCheckDomain);
    proceed = ssoObject.status === 'success' && !!ssoObject.data.ssoMeta;
  }
  // Proceed if metadata is avalable
  if (proceed) {
    const loginURL = ssoObject.data.ssoMeta.idpLoginURL;
    // Following logic is to make redirect work on localhost(only for dev)
    // eslint-disable-next-line prefer-regex-literals
    const pattern = new RegExp('\\b(redirect_uri=).*?(&|#|$)');
    if (
      SSOConfig.isSSOApp &&
      location.hostname === 'localhost' &&
      loginURL.search(pattern) >= 0
    ) {
      location.href = loginURL.replace(
        pattern,
        `$1${SSOConfig.redirectSignIn}$2`,
      );
    } else {
      location.href = loginURL;
    }
    return { ssoUser: true };
  }
  if (ssoObject.data.enterpriseLogin) {
    return {
      enterpriseSignupEmailSent: true,
    };
  }
  return { ssoUser: false };
};

export function secondsToMinutes(seconds) {
  return Math.floor(seconds / 60).toString();
}

async function getRawfilePart(type, part, path, slideType) {
  console.log(slideType, type);
  const typeToSubmit =
    slideType &&
    ['generated', 'uploaded', 'redesign', 'synthesis', 'comply'].includes(
      slideType.toLowerCase(),
    )
      ? 'asset'
      : type;

  const payload = {
    type: typeToSubmit,
    part,
    path,
  };

  if (slideType === 'OP') {
    payload.skip = true;
  }
  return API2.post(`/api2/att/slide`, payload)
    .then((response) => response)
    .catch((error) => {
      console.log('Failed to get file part: ', error);
      if (error?.response?.status === 400) {
        this.setDownloadBlockForUser(true);
      }
      return error?.response?.status;
    });
}

function downloadRawFile(ppt, filename = 'file.pptx') {
  const linkSource = `data:;base64,${ppt}`;
  const downloadLink = document.createElement('a');
  const fileName = filename;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export async function downloadFileWithAPI(
  type,
  path,
  fileName,
  // eslint-disable-next-line default-param-last
  slideType = '',
  progressCallback,
) {
  // Get first part
  let res = await getRawfilePart(type, 0, path, slideType);
  const totalParts = res.total_parts;
  let payload = res.data;

  // Update progress for the first part
  if (progressCallback) progressCallback(Math.round((1 / totalParts) * 100));

  // batch and get files together
  const batchSize = 3;
  for (let part = 1; part < totalParts; part += batchSize) {
    const promises = [];
    for (
      let batch = 0;
      batch < batchSize && part + batch < totalParts;
      batch++
    ) {
      // Get rest of the parts - can be optimised to call multiple parts
      promises.push(getRawfilePart(type, part + batch, path, slideType));
    }
    const responses = await Promise.all(promises);
    for (
      let batch = 0;
      batch < batchSize && part + batch < totalParts;
      batch++
    ) {
      res = responses[batch];
      payload += res.data;

      // Update progress after each part
      if (progressCallback) {
        const progress = Math.round(((part + batch + 1) / totalParts) * 100);
        progressCallback(progress);
      }
    }
  }

  if (fileName) downloadRawFile(payload, fileName);
  else downloadRawFile(payload, res.filename);
}

// 'downloadInProgress' is a callback function which will
// be called when download starts(true) and ends(false)
/* eslint-disable default-param-last */
export function downloadFileWithCustomName(
  url,
  name,
  downloadInProgress,
  addSuffix = true,
  errorCallback,
  progressCallback,
) {
  if (downloadInProgress) downloadInProgress(true);
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      if (progressCallback) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        progressCallback(percentCompleted);
      }
    },
  })
    .then((response) => {
      if (downloadInProgress) downloadInProgress(false);
      const urll = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urll;
      link.setAttribute('download', addSuffix ? `${name}.pptx` : name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
      if (errorCallback) {
        errorCallback();
      }
    });
}

export const getIntervalsForUserListCalls = (lowerBound, upperBound, limit) => {
  const totalIntervals = Math.ceil((upperBound - lowerBound) / limit);
  if (totalIntervals <= 0) {
    return [];
  }
  const intervalsArr = new Array(totalIntervals).fill({ min: 0, max: 0 });
  let i = 0;
  let lb = lowerBound;
  const ub = upperBound;
  while (lb < ub) {
    intervalsArr[i] = {
      min: lb + 1,
      max: lb + limit,
    };
    i++;
    lb += limit;
  }
  return intervalsArr;
};

/* this method returns the number of intervals needed to make parllel API calls
    input = ( minNumId, maxNumId (which comes from count API), fetchCount = defailt number of records or size to fetch )
    output = [ { minIndex: 0, maxIndex: 50 }, { minIndex: 51, maxIndex: 100} ]
 */
export function getIntervalsList(
  minNumId,
  maxNumId,
  fetchCount = defaultFetchCount.GLOBAL_COUNT,
) {
  const intervalsInfoWithIndexes = [];
  if (minNumId > 0) {
    minNumId -= 1;
  }
  const totalIntervals = Math.ceil((maxNumId - minNumId) / fetchCount);
  if (totalIntervals <= 0) return [];
  const intervalsList = Array.from({ length: totalIntervals }, (_, i) => i + 1);
  let updatedMinIndex = null;
  intervalsList.forEach(() => {
    let indexInfo = {};
    if (fetchCount > maxNumId) {
      indexInfo = {
        minIndex: updatedMinIndex || minNumId,
        maxIndex: updatedMinIndex ? updatedMinIndex + fetchCount : fetchCount,
      };
    } else {
      indexInfo = {
        minIndex: updatedMinIndex || minNumId,
        maxIndex: updatedMinIndex
          ? updatedMinIndex + fetchCount - 1
          : minNumId + fetchCount,
      };
    }
    updatedMinIndex = indexInfo.maxIndex + 1;
    intervalsInfoWithIndexes.push(indexInfo);
  });
  return intervalsInfoWithIndexes;
}

export const mySetInterval = (
  intervalCount,
  maxTime,
  intervalCallback,
  timeoutCallback,
) => {
  let timercount = 0;

  const interval = setInterval(() => {
    timercount += intervalCount;

    if (intervalCallback) intervalCallback(interval);

    if (timercount >= maxTime) {
      console.log('*********** Cleared Time Interval *********');
      clearInterval(interval);
      timercount = 0;
      if (timeoutCallback) timeoutCallback();
    }
  }, intervalCount);
};

export const getS3Bucket = (path, isOPUploaded) => {
  if (isOPUploaded) {
    return process.env.VUE_APP_EXTERNAL_SERVICE_BUCKET;
  }
  if (
    path.indexOf('public/') !== -1 ||
    path.indexOf('private/') !== -1 ||
    path.indexOf('protected/') !== -1
  ) {
    return process.env.VUE_APP_MVP_ASSETS_BUCKET;
  }
  if (path.indexOf('newImageReplaceOutput/') !== -1) {
    return process.env.VUE_APP_CALYREX_S3_BUCKET;
  }
  return process.env.VUE_APP_SLIDES_S3_BUCKET;
};

export const categoriesData = [
  { text: 'Colors', key: 'color', avatarColor: '#075689' },
  { text: 'Font', key: 'font', avatarColor: '#AF4FB3' },
  { text: 'Images', key: 'images', avatarColor: '#5C00D8' },
  { text: 'Icons', key: 'icons', avatarColor: '#2AA08A' },
  { text: 'Template', key: 'template', avatarColor: '#EB7224' },
];

export const getTotalSuggestionsInSlide = (slide) => {
  if (slide && slide.suggestions?.data) {
    return categoriesData.map((c, cindex) => {
      let resolvedSuggestionCount = 0;
      const category =
        slide.suggestions.data.slide_data.format_properties.format_suggestions[
          c.key
        ] || [];
      const slideProps =
        slide.suggestions.data.slide_data.slide_properties.size_props;
      const slideObj = {
        text: c.text,
        key: c.key,
        avatarColor: c.avatarColor,
        type: 'format',
        totalSuggestions: getSuggestionsTotalCount(category) || 0,
        suggestions: [],
        resolvedSuggestions: 0,
        index: cindex,
      };
      if (category) {
        if (Array.isArray(category) && category.length !== 0) {
          slideObj.suggestions = category
            .flatMap((categoryItem, csindex) => {
              if (categoryItem.mapping) {
                return categoryItem.mapping.map((suggestion, mindex) => {
                  if (
                    suggestion.status !== undefined &&
                    ['accepted', 'rejected'].indexOf(
                      suggestion.status.toLowerCase(),
                    ) > -1
                  ) {
                    resolvedSuggestionCount += 1;
                  }
                  const { left, top } = suggestion.extents || {};
                  return {
                    ...suggestion,
                    ...categoryItem,
                    avatarColor: c.avatarColor,
                    x:
                      suggestion.extents &&
                      (left || left === 0) &&
                      slideProps.width
                        ? ((left / slideProps.width) * 100).toFixed(2)
                        : null,
                    y:
                      suggestion.extents &&
                      (top || top === 0) &&
                      slideProps.height
                        ? ((top / slideProps.height) * 100).toFixed(2)
                        : null,
                    status: suggestion.status
                      ? suggestion.status.toLowerCase()
                      : null,
                    mappingIndex: mindex,
                    categoryIndex: cindex,
                    categorySubIndex: csindex,
                    category: c.key,
                  };
                });
              }
              return [];
            })
            .map((item, index) => ({
              ...item,
              index: index + 1,
            }));
          slideObj.resolvedSuggestions = resolvedSuggestionCount;
        } else if (
          (c.key === 'template' && category.current_template_info) ||
          category.selected_template_info
        ) {
          let currentTemplate = {};
          let selectedTemplate = {};

          try {
            currentTemplate = JSON.parse(category.current_template_info) || {};
            selectedTemplate =
              JSON.parse(category.selected_template_info) || {};
          } catch (error) {
            console.error('Error parsing template info', error);
            return slideObj;
          }
          slideObj.suggestions = [
            {
              name: 'Slide template',
              currentTemplate,
              selectedTemplate,
              avatarColor: c.avatarColor,
              category: c.key,
              mappingIndex: 0,
              categoryIndex: 3,
              categorySubIndex: 0,
              index: 1,
            },
          ];
        } else {
          slideObj.suggestions = [];
        }
      } else {
        slideObj.suggestions = [];
      }

      return slideObj;
    });
  }
  return [];
};
