import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export const wsInitialState = {
  selectedDropdownAction: '',
  currentSelectedFileId: '',
  createdProjects: [],
  uploadedFiles: [],
};
export const myWorkspace = {
  namespaced: true,
  state: {
    ...wsInitialState,
  },
  getters: {},
  mutations: {
    SET_DROPDOWN_ACTION(state, value) {
      state.selectedDropdownAction = value;
    },
    SET_CURRENT_SELECTED_FILE_ID(state, value) {
      state.currentSelectedFileId = value;
    },
    SET_ALL_FILES(state, value) {
      state.uploadedFiles = value;
    },
    SET_ALL_PROJECTS(state, value) {
      state.createdProjects = value;
    },
  },
  actions: {
    setDropdownAction({ commit }, data) {
      commit('SET_DROPDOWN_ACTION', data);
    },
    setCurrentSelectedFileId({ commit }, data) {
      commit('SET_CURRENT_SELECTED_FILE_ID', data);
    },
    setAllFiles({ commit }, data) {
      commit('SET_ALL_FILES', data);
    },
    setAllProjects({ commit }, data) {
      commit('SET_ALL_PROJECTS', data);
    },
  },
  persist: createPersistedState({
    key: 'vuex_myWorkspace',
    paths: [],
  }),
};
