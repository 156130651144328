export const utf8ToIso = (input) => {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i);
    if (charCode <= 255) {
      output += String.fromCharCode(charCode);
    }
  }
  return output;
};

export const getAccessLevelFormatedStrings = ({ company, team, user }) => {
  const edit = `c__${company.num_id}__e t__${team.num_id}__e u__${user.num_id}__e`;
  const reviewer = `c__${company.num_id}__r t__${team.num_id}__r u__${user.num_id}__r`;
  const viewer = `c__${company.num_id}__v t__${team.num_id}__v u__${user.num_id}__v`;
  const restricted = `c__${company.num_id}__s t__${team.num_id}__s u__${user.num_id}__s`;
  return [restricted, viewer, reviewer, edit];
};

export const templateSort = (templates) => {
  let marked = [];
  let regular = [];
  let disabled = [];
  let featureDisabled = [];

  templates.forEach((template) => {
    if (template.isDisabled) {
      disabled.push(template);
    } else if (template.isMarked) {
      marked.push(template);
    } else if (template.disabled) {
      featureDisabled.push(template);
    } else {
      regular.push(template);
    }
  });

  marked = marked.sort((a, b) => a.name.localeCompare(b.name));
  regular = regular.sort((a, b) => a.name.localeCompare(b.name));
  disabled = disabled.sort((a, b) => a.name.localeCompare(b.name));
  featureDisabled = featureDisabled.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return [...marked, ...regular, ...featureDisabled, ...disabled];
};

export const templateSortForSearch = (templates, selectedTemplateCode) => {
  const templatesWithPriority = templates.map((template) => {
    let priority;

    if (template.code === selectedTemplateCode) {
      priority = 0;
    } else if (template.isDisabled) {
      priority = 5;
    } else if (template.isPinned && template.isMarked) {
      priority = 1;
    } else if (template.isPinned) {
      priority = 2;
    } else if (template.isMarked) {
      priority = 3;
    } else {
      priority = 4;
    }

    return {
      template,
      priority,
    };
  });

  const sorted = templatesWithPriority.sort(
    (a, b) =>
      a.priority - b.priority || a.template.name.localeCompare(b.template.name),
  );

  return sorted.map((item) => item.template);
};
