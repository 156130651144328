<template>
  <v-app class="application-main">
    <v-main>
      <div v-if="!isExistingUser" class="registration-wrapper">
        <div class="registration-content">
          <div v-if="!signedUp" class="registration-content-left">
            <TrialSignUpAdvantages
              :subscriptionType="'Prezent Enterprise'"
            ></TrialSignUpAdvantages>
          </div>
          <div v-if="!signedUp" class="registration-content-right">
            <div class="registration-heading">
              Sign up to Prezent Enterprise
            </div>
            <v-form
              class="registration-form"
              ref="registrationForm"
              v-model="isNotValid"
              lazy-validation
            >
              <v-text-field
                v-model="firstName"
                label="First name"
                :rules="formRules.firstName"
                :disabled="isReadonlyfirstName"
                required
                color="#fff"
                id="first"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="lastName"
                label="Last name"
                :rules="formRules.lastName"
                :disabled="isReadonlylastName"
                required
                color="#fff"
                id="last"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="email"
                :label="
                  isFocused || email
                    ? 'Work email'
                    : 'Work email (Ex. john.doe@company.com)'
                "
                :rules="formRules.email"
                :disabled="isReadonlyemail"
                required
                color="#fff"
                id="email"
                @focus="isFocused = true"
                @blur="handleEmailChange"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="companyName"
                label="Company name"
                :rules="formRules.companyName"
                required
                color="#fff"
                id="companyName"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <div class="terms">
                <p>
                  By clicking below, you agree to our
                  <a href="https://prezent.ai/terms-of-service/" target="_blank"
                    >Terms of Service</a
                  >
                  and
                  <a href="https://prezent.ai/privacy-policy/" target="_blank"
                    >Privacy Policy</a
                  >.
                </p>
              </div>
              <div style="text-align: left">
                <v-btn
                  @click="submit"
                  :disabled="
                    !isNotValid ||
                    !firstName ||
                    !lastName ||
                    !isEmailValid ||
                    !companyName
                  "
                  color="primary"
                  rounded
                  min-width="144"
                  height="50"
                  id="submit"
                  :loading="isSignupLoading"
                >
                  Continue
                </v-btn>
              </div>
            </v-form>
          </div>
          <div v-else class="confirmation-wrapper">
            <div class="confirmation">
              <v-img
                src="/assets/img/feather_check-circle.svg"
                max-width="60px"
                max-height="60px"
                alt
              />
              <p class="heading">{{ signUpHeading }}</p>

              <div class="subText" v-html="returnMarked(getMessage)"></div>
            </div>
          </div>
        </div>
      </div>
      <Redirect v-else />
    </v-main>
  </v-app>
</template>

<script>
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { isEmojiExist, isEmailValid } from '../../../components/utils';
import CustomRegistrationApi from '../../../API/custom-registration-api';
import Redirect from './Redirect.vue';
import { capitalizeFirstLetter } from '../../../utils/common';
import TrialSignUpAdvantages from '../../../components/common/TrialSignUpAdvantages.vue';

export default {
  name: 'SignUpNew',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      isNotValid: true,
      formRules: {
        firstName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.firstNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        lastName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.lastNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        email: [(v) => isEmailValid(v) || this.$t('warnings.emailMustBeValid')],
        companyName: [
          (v) => !!v || this.$t('warnings.companyNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
      },
      isReadonlyfirstName: false,
      isReadonlylastName: false,
      isReadonlyemail: false,
      isSignupLoading: false,
      signedUp: false,
      isExistingUser: false,
      signUpHeading: 'Thank you for registering!',
      isWaitListCompany: false,
      enterpriseDefaultTeamExist: false,
      counter: 5,
      isFocused: false,
    };
  },
  components: {
    Redirect,
    TrialSignUpAdvantages,
  },
  methods: {
    returnMarked(content) {
      return DOMPurify.sanitize(
        `<div class="marked-container">${marked(content)}</div>`,
      );
    },
    submit() {
      this.isSignupLoading = true;
      const payload = [
        {
          email: this.email?.toLowerCase(),
          firstname: this.firstName,
          lastname: this.lastName,
          companyName: this.companyName,
        },
      ];

      CustomRegistrationApi.methods
        .register(payload)
        .then((resp) => {
          if (!resp.error) {
            console.log(resp);
            this.signedUp = true;
          } else {
            this.enterpriseDefaultTeamExist =
              resp?.data[0]?.enterpriseDefaultTeamExist;
            this.isExistingUser = resp?.data[0]?.userAlreadyExist;
            this.isWaitListCompany = resp?.data[0]?.isWaitListCompany;
            if (this.isExistingUser) {
              setTimeout(() => {
                this.$router.push('/signin', () => {});
              }, 3000);
            }
            if (this.isWaitListCompany) {
              this.startCounter('mainWebsite');
              this.signedUp = true;
              this.signUpHeading = 'Thank you for your interest in Prezent!';
            }
            if (this.enterpriseDefaultTeamExist) {
              this.startCounter();
              this.signedUp = true;
              this.signUpHeading =
                'Yay! Your organization already has an Enterprise subscription with Prezent';
            }
          }
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            console.log('error');
          } else if (error?.response?.status === 500) {
            this.isWaitListCompany = true;
            this.signedUp = true;
            this.signUpHeading = 'Thank you for your interest in Prezent!';
          }
        })
        .finally(() => {
          this.isSignupLoading = false;
        });
    },
    handleEmailChange() {
      this.isFocused = false;
      if (this.email.includes('@') && this.email.includes('.')) {
        const companyDomain = this.email.split('@')[1];
        const cNameArray = companyDomain.split('.');
        const cName = cNameArray && cNameArray.length ? cNameArray[0] : '';
        this.companyName = capitalizeFirstLetter(cName);
      } else {
        this.companyName = '';
      }
    },
    startCounter(redirectLocation) {
      const countDownTimer = setInterval(() => {
        this.counter -= 1;
        if (this.counter < 1) {
          clearInterval(countDownTimer);
          if (redirectLocation && redirectLocation === 'mainWebsite') {
            window.location.href = 'https://prezent.ai';
          } else {
            this.$router.push('/signin', () => {});
          }
        }
      }, 1000);
    },
  },
  computed: {
    isEmailValid() {
      return isEmailValid(this.email);
    },
    getMessage() {
      if (this.isWaitListCompany) {
        return `<p>We have added you to our waitlist <br /> and will get back to you soon.</p><br />  Redirecting you to the main website in ${this.counter}s...</p>`;
      }
      if (this.enterpriseDefaultTeamExist) {
        return `<p>You can use your corporate email id and password to log in to Prezent<br /> <br />  Redirecting you to the login page in ${this.counter}s...</p>`;
      }
      return '<p>We have emailed you a link.<br /> Please use it to complete the <br /> sign-up process and begin <br /> using Prezent.</p>';
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-wrapper {
  margin: auto;
  // padding: 2rem;
  width: 100%;

  p {
    font-size: 14px;
  }
  .registration-content {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .registration-content-right {
      width: 50%;
      color: #fff !important;
      background-color: #1075ab;
      .registration-heading {
        font-weight: 400px;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 25px;
        padding-top: 50px;
        padding-left: 100px;
      }
      .registration-form {
        width: 500px;
        padding: 10px 30px 0px 100px;
        ::v-deep .v-input input {
          color: #fff !important;
        }
        ::v-deep .v-input__slot {
          margin-bottom: 0px !important;
        }
        ::v-deep .v-input .v-label {
          color: #fff !important;
        }
        ::v-deep .v-input__slot:before {
          border-color: #fff !important;
        }
        ::v-deep .v-input__slot::after {
          border-color: #fff !important;
        }
        ::v-deep.error--text .v-input__slot:before {
          border-color: #f70000 !important;
        }
        ::v-deep.error--text .v-input__slot::after {
          border-color: #f70000 !important;
        }
        ::v-deep.v-messages__wrapper {
          height: 25px;
        }
        ::v-deep.v-messages__message {
          position: absolute;
          color: #fff; /* Change the text color */
          font-size: 12px; /* Adjust the font size */
          font-weight: 400; /* Adjust the font size */
          background-color: #f70000; /* Add a background color */
          padding: 4px; /* Add some padding */
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .v-messages__message::before {
          content: '\f059'; /* Font Awesome "info-circle" icon or similar */
          font-family: 'Font Awesome 5 Free'; /* Make sure Font Awesome is available */
          font-weight: 900; /* Needed for solid Font Awesome icons */
          color: #fff; /* Icon color */
          margin-right: 8px; /* Space between the icon and the text */
          cursor: pointer; /* Show pointer on hover for the icon */
          display: inline-block;
          vertical-align: middle;
          position: relative;
        }
        .v-messages__message:hover::before::after {
          content: 'This field is required'; /* Tooltip text */
          position: absolute;
          top: -24px;
          left: 0;
          background-color: black;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          z-index: 9999;
        }

        /* Triangle for tooltip */
        .v-messages__message:hover::before::before {
          content: '';
          position: absolute;
          top: -8px;
          left: 10px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent black transparent;
        }
        p {
          margin-top: 20px;
        }
        button {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 30px;
          text-align: center;
          text-transform: none;
        }
        .error {
          background-color: transparent !important;
          border-color: transparent !important;
          color: red;
        }
      }
    }
    .confirmation-wrapper {
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: center;
      margin-bottom: 50px;
      text-align: center;
      width: 40%;
      .confirmation {
        .v-image {
          margin: 0 auto;
          margin-bottom: 10px;
        }
        .heading {
          font-size: 24px;
          margin-bottom: 16px;
          line-height: 36px;
        }
        .subText {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.terms {
  p {
    margin-bottom: 0px;
  }
  a {
    text-decoration: underline;
    color: #fff !important;
  }
}
.registration-content-left {
  padding: 50px 80px 0px 80px;
  width: 50%;
}
</style>
