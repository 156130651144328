import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth } from 'aws-amplify';

import store from '../store';
import SignInCustom from '../pages/SignInCustom';
import Redirect from '../pages/Redirect';
import SsoUserDisabled from '../pages/SsoUserDisabled';
import SsoLogin from '../pages/SsoLogin';
import FingerPrintTest from '../pages/FingerPrintTest';
import FingerPrintDetail from '../pages/FingerPrintDetail';
import FingerprintSignin from '../pages/FingerprintSignin';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import TrialSignUpNew from '../pages/TrialSignUpNew';
import TrialRegistrationNew from '../pages/TrialRegistrationNew';
import Welcome from '../pages/Welcome';
import Home from '../pages/Home';
import MainSection from '../components/Main/MainSection/MainSection.vue';
import NewPrezentation from '../components/Main/NewPrezentation/NewPrezentation.vue';
import PrezentationUpload from '../components/Main/PrezentationUpload/PrezentationUpload.vue';
import MyPrezentation from '../components/Main/MyPrezentation/MyPrezentation.vue';
import PrezentationDetail from '../components/Main/MyPrezentation/PrezentationDetail.vue';
import Prezentations from '../components/Main/Prezentations/Prezentations.vue';
import FingerPrints from '../components/Main/FingerPrints/FingerPrints.vue';
import OvernightPrezentationContainer from '../components/Main/OvernightPrezentations/OvernightPrezentationContainer.vue';
import OvernightPrezentationDetailContainer from '../components/Main/OvernightPrezentations/OvernightPrezentationDetailContainer.vue';
import GenerateSlidesContainer from '../components/Main/GenerateSlides/GenerateSlidesContainer.vue';
import SynthesizeContainer from '../components/Main/Synthesize/SynthesizeContainer.vue';
import RedesignContainer from '../components/Main/Redesign/RedesignContainer.vue';
import Slides from '../components/Main/Slides/Slides.vue';
// import SlideDetailView from '../components/Main/Slides/SlideDetailView.vue';
import SlideDetailView from '../components/Main/Slides/SlideDetailViewV2.vue';
import Know from '../components/Main/Know/KnowContainer.vue';
// import KnowDetails from '../components/Main/Know/KnowDetails.vue';
import Learn from '../components/Main/Learn/Learn.vue';
import LearnLanding from '../components/Main/Learn/LearnLanding.vue';
import CourseDetail from '../components/Main/Learn/CourseDetail.vue';
import SurveyStandAlone from '../components/common/SurveyStandAlone.vue';
import CommunicationStandAlone from '../components/common/CommunicationStandAlone';
// import LearnFinish from "./components/Main/Learn/LearnFinish.vue";
// import Initial from "./components/Main/Learn/Initial.vue";
// import Continue from "./components/Main/Learn/Continue.vue";
// import Choose from "./components/Main/Learn/Choose.vue";
import Novice from '../components/Main/Learn/Novice/Novice.vue';
import Practice from '../components/Main/Learn/Novice/Practice.vue';
import Practice2 from '../components/Main/Learn/Novice/Practice2/Practice2.vue';
import Practice5 from '../components/Main/Learn/Novice/Practice5/Practice5.vue';
import Quiz from '../components/Main/Learn/Novice/Quiz.vue';
import Profile from '../components/Main/Profile/Profile.vue';
import FingerPrint from '../components/Main/Zentrends/FingerPrint.vue';
import SearchResults from '../components/Main/SearchResults/SearchResults.vue';
import Tutorials from '../components/Main/Help/Tutorials/Tutorials.vue';
import FAQs from '../components/Main/Help/FAQs/FAQs.vue';
import Search from '../components/Main/Search/Search.vue';
import SuperAdminLogin from '../pages/SuperAdminLogin';
import Quicktips from '../components/Main/Help/Quicktips';
import Walkthroughs from '../components/Main/Help/Walkthroughs/Walkthroughs.vue';
import CustomRegistration from '../pages/CustomRegistration.vue';
import PremiumOrStandardRegistrationNew from '../pages/PremiumOrStandardRegistrationNew.vue';
import PremiumAndStandardRegistrationPayment from '../pages/PremiumAndStandardRegistrationPayment.vue';
import EnterpriseSignupNew from '../pages/EnterpriseSignupNew.vue';
import UnSubscribe from '../components/Main/UnSubscribe/UnSubscribe.vue';
import { MatomoAnalyticsHandler } from '../components/common/Analytics/MatomoAnalyticsHandler';
import ComplyMain from '../components/Main/ComplianceChecker/ComplyMain.vue';

import ViewStoryline from '../components/Main/Storyline/ViewStoryline.vue';
import AcceleratorsContainer from '../components/Main/Accelerators/AcceleratorsContainer.vue';
import AddNewTemplateContainer from '../pages/components/TemplateDesigner/AddNewTemplateContainer.vue';
import ZenithWorkshopContainer from '../components/Main/ZenithWorkshop/ZenithWorkshopContainer.vue';
import MyWorkspaceContainer from '../components/Main/MyWorkspace/MyWorkspaceContainer.vue';
// import storageHelper from 'storage-helper'
Vue.use(VueRouter);
Vue.use(store);

const InitialisePendo = (data) => {
  // eslint-disable-next-line no-undef
  if (pendo) {
    const companyName = data['custom:companyName'];
    const organizationName = data['custom:organizationName'];
    const role = data['custom:role'];
    const teamName = data['custom:teamName'];
    const { name, email, family_name: familyName } = data;
    const queue = [];
    let isGuideVisible = false;
    const featurePopups = [
      'yVtz_iP59_ZNqf-hyZTKvJpwVJo',
      'pBo1g1DPFWYbDaBZINkzN2SQo8c',
      '6ONDopkFdgxWd4METGBTJeGTwzU',
      'kSQJGmbT9f7Km3NgV6Cm2ubfWVQ',
      'ttZjHI7dTRJWOIO1doch1iAunnw',
      'NSn68AHwUaBh1ByUZPzehbNjRHI',
      'RZY5qcF5IJ_PglHhLL5NnUju7qw',
      'KOHN_vfxE8wUY0rrq5Od3jupQKM',
      'poJw7DpG8_d3Iv9sRBehjpd_Ns4',
      'aWZ1IH8fwrCaUU3-inMTLv_wJVw',
      '6H-eNfH0X2b9-xFPjhZMABoQ6eQ',
      'QIgrHB9LBEYf2C-fSLr_DKvCh_c',
      'h9-W-9HlML2mXfG08BPNIcTqAuA',
      'edqhxD_dIGOgQMYDX9Te8cKbn3w',
      'ueUvP84iIzZMfy5kxDi1M_BgDI4',
      'xzpjjzs0EwFCV4Zvugf8zFsVtpM',
      'quq7pK67Qf3RsaJu5SuvJtw4kyo',
      'XaIATcGT2Kh0aXMMAqHFU5krP6E',
    ];
    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: email,
        email,
        full_name: `${name} ${familyName}`,
        role,
      },

      account: {
        id: `${companyName} - ${organizationName}`,
        companyName,
        teamName,
        organizationName,
      },
      guides: {
        globalScripts: [
          {
            beforeMount(event) {
              if (featurePopups.includes(event.step.guideId)) {
                if (isGuideVisible) {
                  queue.push(event.step.guideId);
                  event.cancel = true;
                }
                isGuideVisible = true;
              }
            },
            unmounted(event) {
              if (featurePopups.includes(event.step.guideId)) {
                isGuideVisible = false;
                if (event.reason === 'dismissed') {
                  setTimeout(() => {
                    // eslint-disable-next-line no-undef
                    const isAnyGuideVisisble = !!pendo.guides.find((guide) =>
                      guide.isShown(),
                    );
                    if (!isAnyGuideVisisble && queue.length) {
                      // eslint-disable-next-line no-undef
                      pendo.guides.find((g) => g.id === queue[0])?.show();
                      queue.shift();
                    }
                  }, 1000);
                }
              }
            },
          },
        ],
      },
      events: {
        guidesLoaded: () => {
          // eslint-disable-next-line no-undef
          const pendoGuides = pendo.guides.map((g) => {
            const hasBeenSeen = g.hasBeenSeen() || g.isComplete();
            localStorage.setItem(g.id, hasBeenSeen);

            return {
              id: g.id,
              hasBeenSeen,
              show: g.show,
            };
          });
          store.commit('pendoStore/SET_GUIDES', pendoGuides);
        },
      },
    });
  }
};

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: { requiresAuth: true, title: 'Prezent' },
    },
    {
      path: '/addDesignerTemplate',
      name: 'addDesignerTemplate',
      component: AddNewTemplateContainer,
      meta: { requiresAuth: true },
    },
    {
      path: '/standalone-survey/:id',
      name: 'Survey',
      component: SurveyStandAlone,
    },
    {
      path: '/manage_notification',
      name: 'Communication',
      component: CommunicationStandAlone,
    },
    {
      path: '/unsubscribe',
      name: 'UnSubscribe',
      component: UnSubscribe,
      meta: { requiresAuth: true, title: 'Prezent' },
    },
    {
      path: '/trialsignup',
      name: 'TrialSignUp',
      component: TrialSignUpNew,
    },
    {
      path: '/trialregistration',
      name: 'TrialRegistration',
      component: TrialRegistrationNew,
    },
    {
      path: '/signin',
      name: 'SignInCustom',
      component: SignInCustom,
      children: [
        {
          path: ':errorCode',
          name: 'SignInCustom',
          component: SignInCustom,
          props: true,
        },
      ],
    },
    {
      path: '/impersonate',
      name: 'SuperAdminLogin',
      component: SuperAdminLogin,
      meta: { superadmins: true },
    },
    {
      path: '/redirect',
      name: 'Redirect',
      component: Redirect,
    },
    {
      path: '/userdisabled',
      name: 'SsoUserDisabled',
      component: SsoUserDisabled,
    },
    {
      path: '/sso/login',
      name: 'SsoLogin',
      component: SsoLogin,
    },
    {
      path: '/audience-fingerprint',
      name: 'audience-fingerprint',
      component: FingerPrintDetail,
    },
    {
      path: '/fingerprinttest',
      name: 'fingerprint-test',
      component: FingerPrintTest,
    },
    {
      path: '/fingerprintsignin',
      name: 'fingerprint-sign',
      component: FingerprintSignin,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password-page',
      component: ForgotPassword,
    },
    {
      path: '/reset-password',
      name: 'reset-password-page',
      component: ResetPassword,
    },
    {
      path: '/enterpriseregistration',
      name: 'custom-registration',
      component: CustomRegistration,
    },
    // https://prezentium.atlassian.net/browse/PM-7994
    {
      path: '/customregistration',
      redirect: '/enterpriseregistration',
    },
    {
      path: '/premiumregistration',
      name: 'premium-registration',
      component: PremiumOrStandardRegistrationNew,
    },
    {
      path: '/standardregistration',
      name: 'standard-registration',
      component: PremiumOrStandardRegistrationNew,
    },
    {
      path: '/premiumregistration/:id',
      name: 'premium-registration-payment',
      component: PremiumAndStandardRegistrationPayment,
    },
    {
      path: '/standardregistration/:id',
      name: 'standard-registration-payment',
      component: PremiumAndStandardRegistrationPayment,
    },
    {
      path: '/enterprisesignup',
      name: 'enterprise-signup',
      component: EnterpriseSignupNew,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          redirect: 'main',
        },
        // TODO angie: Need to check if this is used
        {
          path: 'search',
          name: 'search',
          component: Search,
          meta: { checkSubscription: true },
        },
        {
          path: 'main',
          name: 'main',
          component: MainSection,
          children: [
            {
              path: ':survey?',
              name: 'main.survey',
              component: MainSection,
            },
          ],
        },
        {
          path: 'build',
          name: 'build',
          component: NewPrezentation,
          meta: { checkSubscription: true, routeKey: 'build' },
        },
        {
          path: 'upload',
          name: 'upload',
          component: PrezentationUpload,
          meta: { checkSubscription: true, routeKey: 'upload' },
          children: [
            {
              path: ':id/transfer',
              name: 'upload.transfer',
              component: PrezentationUpload,
              meta: { checkSubscription: true, isTransferFlow: true },
            },
          ],
        },
        {
          path: 'template-converter',
          name: 'template-converter',
          component: ComplyMain,
          meta: { checkSubscription: true, routeKey: 'compliance' },
        },
        {
          path: 'myPrezentation',
          name: 'myPrezentation',
          component: MyPrezentation,
          meta: { checkSubscription: true, routeKey: 'prezentations' },
        },
        {
          path: 'myPrezentation/:id',
          component: PrezentationDetail,
          name: 'myPrezentation.detail',
          props: true,
          meta: { checkSubscription: true, routeKey: 'prezentations' },
        },
        {
          path: 'prezentations',
          name: 'Prezentations',
          component: Prezentations,
          meta: { checkSubscription: true, routeKey: 'prezentations' },
        },
        {
          path: 'slides',
          name: 'slides',
          component: Slides,
          meta: { checkSubscription: true, routeKey: 'slides' },
        },
        {
          path: 'slides/:id',
          name: 'slide-detail',
          component: SlideDetailView,
          props: true,
          meta: { checkSubscription: true, routeKey: 'slides' },
        },
        {
          path: 'fingerprints',
          name: 'fingerprints',
          component: FingerPrints,
          meta: { checkSubscription: true },
        },
        {
          path: 'fingerprint',
          name: 'fingerprint',
          component: FingerPrint,
          meta: { checkSubscription: true, routeKey: 'fingerprint' },
        },
        {
          path: 'overnight-prezentations',
          name: 'overnightPrezentations',
          component: OvernightPrezentationContainer,
          meta: {
            checkSubscription: true,
            routeKey: 'overnight-prezentations',
          },
        },
        {
          path: 'generate',
          name: 'generate',
          component: GenerateSlidesContainer,
          meta: { checkSubscription: true, routeKey: 'generate' },
        },
        {
          path: 'synthesis',
          name: 'synthesis',
          component: SynthesizeContainer,
          meta: { checkSubscription: true, routeKey: 'synthesis' },
        },
        {
          path: 'redesign',
          name: 'redesign',
          component: RedesignContainer,
          meta: { checkSubscription: true, routeKey: 'redesign' },
        },
        {
          path: 'my-overnight-prezentation/:prezentationID',
          component: OvernightPrezentationDetailContainer,
          name: 'overnightPrezentation.detail',
          props: true,
          meta: {
            checkSubscription: true,
            routeKey: 'overnight-prezentations',
          },
        },
        {
          path: 'searchResults',
          name: 'searchResults',
          component: SearchResults,
        },
        {
          path: 'learn',
          name: 'learn',
          redirect: {
            name: 'learn.landing',
          },
          component: Learn,
          meta: { checkSubscription: true, routeKey: 'learn' },
          children: [
            {
              path: ':courseid?',
              component: LearnLanding,
              name: 'learn.landing',
              meta: { checkSubscription: true, routeKey: 'learn' },
              props: true,
            },
            {
              path: 'course/:id/:assetid?',
              name: 'course.detail',
              component: CourseDetail,
              props: true,
              meta: { checkSubscription: true, routeKey: 'learn' },
            },
            {
              path: 'novice',
              component: Novice,
              children: [
                {
                  path: '',
                  redirect: 'practice',
                  meta: { checkSubscription: true, routeKey: 'learn' },
                },
                {
                  path: 'practiceQuestions/:assetId/:courseId',
                  name: 'learn.practice.questions',
                  component: Practice,
                  meta: { checkSubscription: true, routeKey: 'learn' },
                  props: true,
                },
                {
                  path: 'issueTrees/:assetId/:courseId',
                  name: 'learn.practice.issueTrees',
                  component: Practice2,
                  meta: { checkSubscription: true, routeKey: 'learn' },
                },
                {
                  path: 'artOfSlides/:assetId/:courseId',
                  name: 'learn.practice.artOfSlides',
                  component: Practice5,
                  meta: { checkSubscription: true, routeKey: 'learn' },
                },
                {
                  path: 'quiz/:assetId/:courseId',
                  name: 'learn.quiz',
                  component: Quiz,
                  props: true,
                  meta: { checkSubscription: true, routeKey: 'learn' },
                },
              ],
            },
          ],
        },

        {
          path: 'best-practice-library',
          name: 'best-practice-library',
          component: Know,
          meta: { checkSubscription: true, routeKey: 'best-pratice-guides' },
        },

        {
          path: 'best-practice-library/:id',
          component: SlideDetailView,
          name: 'know.detail',
          props: true,
          meta: { checkSubscription: true, routeKey: 'best-pratice-guides' },
        },
        {
          path: 'best-practice-library-decks/:id',
          component: PrezentationDetail,
          name: 'bestPractice.detail',
          props: true,
          meta: { checkSubscription: true, routeKey: 'best-pratice-guides' },
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile,
          meta: { checkSubscription: true },
        },

        {
          path: 'storyline/:id',
          component: ViewStoryline,
          name: 'storyline.detail',
          props: true,
          meta: { checkSubscription: true },
        },
        {
          path: 'accelerators',
          name: 'accelerators',
          component: AcceleratorsContainer,
          meta: {
            checkSubscription: true,
            routeKey: 'accelerators',
          },
        },
        {
          path: 'zenith-learning-workshops',
          name: 'zenithWorkshop',
          component: ZenithWorkshopContainer,
          meta: {
            checkSubscription: true,
            routeKey: 'zenith-learning-workshops',
          },
        },
        {
          path: 'my-workspace',
          name: 'myWorkspace',
          component: MyWorkspaceContainer,
          meta: {
            checkSubscription: true,
            routeKey: 'my-workspace',
          },
        },
      ],
    },
    {
      path: '/help',
      name: 'help',
      component: Home,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          redirect: '/home',
        },
        {
          path: 'tutorials',
          name: 'tutorials',
          component: Tutorials,
          meta: { checkSubscription: true },
        },
        {
          path: 'faqs',
          name: 'faqs',
          component: FAQs,
          meta: { checkSubscription: true },
        },
        {
          path: 'quicktips',
          name: 'quicktips',
          component: Quicktips,
          meta: { checkSubscription: true },
        },
        {
          path: 'walkthroughs',
          name: 'walkthroughs',
          component: Walkthroughs,
          meta: { checkSubscription: true },
        },
      ],
    },
  ],
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(async (to, from, next) => {
  if (from) {
    // Save the previous route in the router instance
    router.app.previousRoute = from;
    next();
  }
  if (!to.matched.length) {
    next('/home');
  }
  if (
    to.matched.some((record) => record.meta.checkSubscription) &&
    store.state &&
    store.state.users.currentUser.subscriptions &&
    store.state.users.currentUser.subscriptions[0].isExpired
  ) {
    const blockList = [
      'fingerprint',
      'templates',
      'metrics',
      'wish',
      'storylines',
    ];
    if (
      (to.path === '/profile' || to.path.startsWith('/help')) &&
      !blockList.includes(to.query && to.query.tab)
    ) {
      next();
    } else {
      next('/home/main');
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user.attributes) {
          MatomoAnalyticsHandler.setUser(user.attributes);
          InitialisePendo(user.attributes);
        }
        next();
      })
      .catch((err) => {
        console.log(err);
        sessionStorage.removeItem('isInitialSlideFilterOpened');
        sessionStorage.setItem(
          'toLink',
          window.location.pathname + window.location.search,
        );
        next({
          path: '/signin',
          query: { redirect: to.path },
        });
      });
  } else {
    next();
  }
  if (
    // Restricting users to access home components when they have converted from audience to user and not completed template designer flow
    to.matched.some((record) => record.name.toLowerCase() === 'home') &&
    store.state &&
    store.state.users &&
    store.state.users.currentUser &&
    store.state.users.currentUser.user &&
    store.state.users.currentUser.company?.isNonBranded &&
    store.state.users.currentUser.user.showTemplateDesignerFlow === 0 &&
    store.state.users.currentUser.user.isFirstUserInTeam
  ) {
    next(`/welcome?email=${store.state.users.currentUser.user.id}`);
  } else if (
    // Restricting users to access home components when they have not completed fingerprint test
    to.matched.some((record) => record.name.toLowerCase() === 'home') &&
    store.state &&
    store.state.users &&
    store.state.users.currentUser &&
    store.state.users.currentUser.user &&
    (store.state.users.currentUser.user.fingerPrint === null ||
      store.state.users.currentUser.user.fingerPrint === undefined)
  ) {
    next(`/welcome?email=${store.state.users.currentUser.user.id}`);
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.protected)) {
    console.log('protected going');
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: '/fingerprintsignin',
        });
      });
  }
  if (to.matched.some((record) => record.meta.superadmins)) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups.includes('SuperAdmins')) {
          next();
        } else {
          next({
            path: '/home',
          });
        }
      })
      .catch(() => {
        next({
          path: '/fingerprintsignin',
        });
      });
  }
});

export default router;
