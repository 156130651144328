import { API, graphqlOperation } from 'aws-amplify';
import createPersistedState from 'vuex-persistedstate';
import { prezentationByOwner, prezentationByCompany } from '@/graphql/queries';
import {
  getPrezentationByID,
  getPrezentationCommentsByID,
} from '@/utils/api-helper';
import { getThumbnail as getThumb } from './helper';
import { cloneDeep } from 'lodash';

// const placeholder = `/assets/img/slides/placeholder-slide.svg`;

export function decoratePrezentation(presentation, currentUser) {
  const getThumbnail = (url) => {
    return getThumb(url, currentUser.id, currentUser.userIp);
  };

  presentation.slides = [];
  presentation.thumbnail = null; // Add this so that vue watchers can keep track
  const { sections } = presentation;
  if (sections?.list && sections.list.length > 0) {
    let assetId = null;
    sections.list.forEach((section) => {
      if (section.slides[0] && !assetId) {
        assetId = section.slides[0].assetId;
      }
    });

    getThumbnail(assetId).then((url) => {
      presentation.thumbnail = url;
    });

    return presentation;
  }
}

// const exemptKeys = [];

export const prezentationInitialState = {
  allPrezentations: [],
  allTeamPrezentations: [],
  allPrezentationsForReview: [],
  allMyPrezentationsCreated: [],
  allMyPrezentationsReview: [],
  myCurrentPrezentationEdit: null,
  myCurrentPrezentationReview: null,
  selectedPrezentationView: null,
  currNewPrezentation: null,
  nextTokenToFetchPrezenations: null,
  reloadOnLastPrezDelete: false,
  allPrezsForHybridSearch: [],
  snapshot: {},
  prezentationViewOrigin: null,
  prezentationBackClicked: false,
  saveStorylineTimeStart: null,
  isStoryLineGalleryOpen: true,
  deckPrezentationPage: 0,
  isLoadMorePrezentations: true,
};

export const prezentations = {
  namespaced: true,
  state: {
    ...prezentationInitialState,
  },
  getters: {
    getPrezentations: (state) => state.prezentations,
    getCurrNewPrezentation: (state) => state.currNewPrezentation,
    getAllPrezentations: (state) => state.allPrezentations,
    getDeckPrezentationPage: (state) => state.deckPrezentationPage,
    getIsLoadMorePrezentations: (state) => state.isLoadMorePrezentations,
    getAllPrezsForHybridSearch: (state) =>
      state.allPrezsForHybridSearch ? state.allPrezsForHybridSearch : [],
    getSaveStorylineTimeStart: (state) =>
      state.saveStorylineTimeStart ? state.saveStorylineTimeStart : null,
  },
  mutations: {
    SET_PREZS_INTERMITTENTLY_ON_PREZENTATIONS_LANDING(state, prezs) {
      state.allPrezentations = [...state.allPrezentations, ...prezs];
    },
    SET_ALL_PREZS_FOR_HYBRID_SEARCH(state, prezs) {
      state.allPrezsForHybridSearch = prezs
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .slice();
    },
    setAllPrezentations(state, prezentations) {
      state.allPrezentations = prezentations;
    },
    setAllTeamPrezentations(state, prezentations) {
      state.allTeamPrezentations = prezentations
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .slice();
    },
    setAllPrezentationsForReview(state, prezentations) {
      state.allPrezentationsForReview = prezentations
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .slice();
    },
    SET_PREZENTATION_VIEW(state, viewType) {
      state.selectedPrezentationView = viewType;
    },
    SET_CURR_NEW_PREZENTATION(state, currNewPrezentation) {
      state.currNewPrezentation = currNewPrezentation;
    },
    SET_NEXT_TOKEN_FOR_PREZENTATIONS(state, nextToken) {
      state.nextTokenToFetchPrezenations = nextToken;
    },
    UPDATE_PREZENTATION_WITH_ACTION(state, prezentationAndAction) {
      const { prezentation, action, parentPrezId, origin, currentUser } =
        prezentationAndAction;
      if (action === 'addFav' || action === 'removeFav') {
        const indexToUpdateAll = state.allPrezentations.findIndex(
          (prz) =>
          (prz.prezentationId ===
            (prezentation.prezentationId
            )
          )
        );
        if (action === 'addFav') {
          state.allPrezentations[indexToUpdateAll].favourites_by.push(
            currentUser.user.num_id,
          );
        } else if (action === 'removeFav') {
          const indexx = state.allPrezentations[indexToUpdateAll].favourites_by.indexOf(
            currentUser.user.num_id,
          );
          if (indexx > -1) {
            state.allPrezentations[indexToUpdateAll].favourites_by.splice(
              indexx,
              1,
            );
          }
        } else {
          if (indexToUpdateAll >= 0) {
            state.allPrezentations[indexToUpdateAll].isFavorite =
              action === 'addFav' ? true : false;
          }
        }
      } else if (action === 'downloadPrezentation') {
        const indexToUpdate = state.allPrezentations.findIndex(
          (prz) =>
            (prz.prezentationId ? prz.prezentationId : prz.id) ===
            (prezentation.prezentationId
              ? prezentation.prezentationId
              : prezentation.id),
        );
        state.allPrezentations[indexToUpdate].downloaded_by.push(
          currentUser.user.num_id,
        );
      } else if (action === 'updated') {
        const indexToUpdate =
          state.snapshot?.PrezentationCardList?.allPrezentations.findIndex(
            (prz) =>
              (prz.prezentationId) ===
              (prezentation.prezentationId)
          );
        const indexToUpdateAll =
          state.snapshot?.PrezentationCardList?.prezentationsInPage.findIndex(
            (prz) =>
              (prz.prezentationId) ===
              (prezentation.prezentationId)
          );

        if (indexToUpdate >= 0) {
          state.snapshot.PrezentationCardList.allPrezentations[indexToUpdate] =
            prezentation;
        }
        if (indexToUpdate >= 0) {
          state.snapshot.PrezentationCardList.prezentationsInPage[
            indexToUpdateAll
          ] = prezentation;
        }
      } else if (action === 'rename') {
        const indexToUpdateAll = state.allPrezentations.findIndex(
          (prz) => prz.prezentationId === prezentation.prezentationId,
        );
        state.allPrezentations[indexToUpdateAll].name = prezentation.name;
      } else if (action === 'duplicate') {
        const toBeClonedIndexAll = state.allPrezentations.findIndex(
          (item) => item.prezentationId === parentPrezId,
        );
        state.allPrezentations.splice(toBeClonedIndexAll, 0, {
          ...prezentation,
        });
      } else if (action === 'delete') {
        const toBeRemovedIndexAll = state.allPrezentations.findIndex(
          (item) => item.prezentationId === prezentation.prezentationId,
        );
        state.allPrezentations.splice(toBeRemovedIndexAll, 1);
        if (state.filteredAndSearchedPrezentations.length <= 0) {
          state.filteredAndSearchedPrezentations = [...state.allPrezentations];
          state.reloadOnLastPrezDelete = true;
        }
      } else if (action === 'share') {
        const indexToUpdateAll = state.allPrezentations.findIndex(
          (prz) =>
            (prz.prezentationId) ===
            prezentation.prezentationId,
        );
        state.allPrezentations[indexToUpdateAll].shared = prezentation?.shared;
      }
    },
    RELOAD_LAST_PREZ_ON_DELETE(state, val) {
      state.reloadOnLastPrezDelete = val;
    },
    SET_DECK_PREZENTATIONS_PAGE(state, val) {
      state.deckPrezentationPage = val;
    },
    SET_IS_LOAD_MORE_PREZENTATIONS(state, val) {
      state.isLoadMorePrezentations = val;
    },
    SET_STATE_SNAPSHOT(state, snapshot) {
      state.snapshot = snapshot;
    },
    SET_PREZENTATION_VIEW_ORIGIN_SNAPSHOT(state, origin) {
      state.prezentationViewOrigin = origin;
    },
    SET_PREZENTATION_BACK_CLICKED(state, value) {
      state.prezentationBackClicked = value;
    },
    SET_SAVE_STORYLINE_START_TIME(state, val) {
      state.saveStorylineTimeStart = val;
    },
    SET_IS_STORYLINE_GALLERY_OPEN(state, val) {
      state.isStoryLineGalleryOpen = val;
    },
  },
  actions: {
    setPrezsIntermittentlyOnPrezentationsLanding({ commit }, prezs) {
      commit('SET_PREZS_INTERMITTENTLY_ON_PREZENTATIONS_LANDING', prezs);
    },
    setAllPrezsForHybridSearch({ commit }, prezs) {
      commit('SET_ALL_PREZS_FOR_HYBRID_SEARCH', prezs);
    },
    setReloadLastPrezOnDelete({ commit }, val) {
      commit('RELOAD_LAST_PREZ_ON_DELETE', val);
    },

    updatePrezentationWithAction({ commit }, prezentationAndAction) {
      commit('UPDATE_PREZENTATION_WITH_ACTION', prezentationAndAction);
    },

    fetchAllPrezentations({ commit }, owner) {
      return new Promise((resolve, reject) => {
        const prezentations = [];
        // let assetFolder = rootGetters["users/getAssetFolder"];
        if (!owner) {
          commit('setAllPrezentations', prezentations);
          resolve(prezentations);
        } else {
          API.graphql(
            graphqlOperation(prezentationByOwner, {
              ownerID: owner.id,
              limit: 1000,
            }),
          )
            .then((prezentationsData) => {
              prezentationsData.data.prezentationByOwner.items.forEach(
                (item) => {
                  prezentations.push(decoratePrezentation(item, owner));
                },
              );
              commit('setAllPrezentations', prezentations);

              resolve(prezentations);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      });
    },

    setUserSelectedPrezentationView(context, prezentationViewType) {
      context.commit('SET_PREZENTATION_VIEW', prezentationViewType);
    },

    setNextTokenToFetchPrezenations(context, nextToken) {
      context.commit('SET_NEXT_TOKEN_FOR_PREZENTATIONS', nextToken);
    },

    fetchAllTeamPrezentations({ commit }, user) {
      const getThumbnail = (url) => getThumb(url, user.id, user.userIp);
      return new Promise((resolve, reject) => {
        const prezentations = [];
        let teamPrez = [];
        // let assetFolder = rootGetters["users/getAssetFolder"];
        if (!user) {
          commit('setAllPrezentations', prezentations);
          resolve(prezentations);
        } else {
          API.graphql(
            graphqlOperation(prezentationByCompany, {
              companyID: user.companyID,
              limit: 1000,
            }),
          )
            .then((prezentationsData) => {
              teamPrez =
                prezentationsData.data.prezentationByCompany.items.filter(
                  (prez) =>
                    prez.visibleTeam &&
                    prez.teamID &&
                    prez.teamID === user.teamID,
                );
              teamPrez.forEach((item) => {
                prezentations.push(item);
              });
              commit('setAllTeamPrezentations', prezentations);

              resolve(prezentations);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      });
    },

    fetchAllPrezentationsForReview({ commit }, reviewData) {
      const getThumbnail = (url) =>
        getThumb(url, reviewData.userId, reviewData.userIp);
      return new Promise((resolve, reject) => {
        const prezentations = [];
        // let assetFolder = rootGetters["recommended/getAssetFolder"];
        if (!reviewData.companyID) {
          commit('setAllPrezentationsForReview', prezentations);
          resolve(prezentations);
        } else {
          API.graphql(
            graphqlOperation(prezentationByCompany, {
              companyID: reviewData.companyID,
            }),
          )
            .then((prezentationsData) => {
              prezentationsData.data.prezentationByCompany.items.forEach(
                (item) => {
                  if (
                    item.reviewersIDs &&
                    item.reviewersIDs.length > 0 &&
                    item.reviewersIDs.find((id) => id === reviewData.userId)
                  ) {
                    prezentations.push(item);
                  }
                },
              );

              commit('setAllPrezentationsForReview', prezentations);

              resolve(prezentations);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      });
    },

    fetchPrezentationComments(context, prezentationID) {
      return new Promise(async (resolve, reject) => {
        const comments = [];
        getPrezentationCommentsByID(prezentationID)
          .then((response) => {
            response.data.forEach((item) => {
              comments.push(item);
            });
            resolve(comments);
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },

    async fetchPrezentationById(context, { prezentationId, currentUser }) {
      return new Promise(async (resolve, reject) => {
        let prezentation;

        if (!prezentationId) {
          context.commit('prezentationById', prezentations);
          resolve(prezentation);
        } else {
          await getPrezentationByID(prezentationId)
            .then((response) => {
              prezentation = decoratePrezentation(response, currentUser);
              resolve(prezentation);
            })
            .catch((err) => {
              reject(err.message);
            });
        }
      });
    },

    setCurrNewPrezentation({ commit }, currNewPrezentation) {
      commit('SET_CURR_NEW_PREZENTATION', currNewPrezentation);
    },

    setAllPrezentationsForLandingPage({ commit }, prezentations) {
      commit('setAllPrezentations', prezentations);
    },

  
    setDeckPrezentationsPage({ commit }, text) {
      commit('SET_DECK_PREZENTATIONS_PAGE', text);
    },

    setIsLoadMorePrezentations({ commit }, text) {
      commit('SET_IS_LOAD_MORE_PREZENTATIONS', text);
    },

    setPrezentationStateSnapshot({ commit }, snapshot) {
      commit('SET_STATE_SNAPSHOT', cloneDeep(snapshot));
    },

    setPrezentationViewOrigin({ commit }, origin) {
      commit('SET_PREZENTATION_VIEW_ORIGIN_SNAPSHOT', origin);
    },
    setPrezentationBackClicked({ commit }, value) {
      commit('SET_PREZENTATION_BACK_CLICKED', value);
    },

    setSaveStorylineStartTime({ commit }, val) {
      commit('SET_SAVE_STORYLINE_START_TIME', val);
    },
    setIsStorylineGalleryOpen({ commit }, val) {
      commit('SET_IS_STORYLINE_GALLERY_OPEN', val);
    },
  },
  persist: createPersistedState({
    key: 'vuex_prezentations',
    paths: [],
  }),
};
