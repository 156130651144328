import { mergeSlideConfig } from '../runtimeConfig';
import { API_MERGE_SLIDE_SERVICE } from './api';
import { retryOperation } from './api-helper';

function preparePayloadData(data) {
  let result = {};
  const errors = [];
  const sectionsData = data.sections;
  const sections = [];
  const { slides } = data;
  // let outputPath = '';
  let outputPath = 'merge_slide_output/';
  let outputBucket = process.env.VUE_APP_MERGE_SLIDES_OUTPUT_BUCKET;
  if (data.outputPath) {
    outputPath = data.outputPath;
  }
  if (data.outputBucket) {
    outputBucket = data.outputBucket;
  }

  // if sectionData exist, takes the priority
  if (sectionsData) {
    const sectionList = sectionsData.list;
    for (let i = 0; i < sectionList.length; i++) {
      const section = sectionList[i];
      const { sectionName } = section;
      const sectionSlides = [];
      for (let j = 0; j < sectionList[i].slides.length; j++) {
        const sectionSlide = sectionList[i].slides[j];
        let fileName = sectionSlide.filename ? sectionSlide.filename : null;
        if (fileName) {
          fileName =
            fileName.charAt(0) === '/' ? fileName.replace(/\//, '') : fileName;
          let source = mergeSlideConfig.slides_bucket_name;
          // FOR IOC Uploaded decks
          source = sectionSlide.isIOCUploaded
            ? mergeSlideConfig.mvp_assets_bucket
            : source;
          // FOR OP processed decks
          source = sectionSlide.isOPUploaded
            ? mergeSlideConfig.external_service_bucket
            : source;
          // FOR Sanitize upload decks
          source =
            sectionSlide.isUserUploaded ||
            sectionSlide.isUploaded ||
            sectionSlide.source === 'uploaded' ||
            sectionSlide.prefs?.source === 'uploaded'
              ? mergeSlideConfig.mvp_assets_bucket
              : source;
          // For Generate decks
          source =
            sectionSlide.isGenerated ||
            sectionSlide.source === 'generated' ||
            sectionSlide.prefs?.source === 'generated'
              ? mergeSlideConfig.mvp_assets_bucket
              : source;
          // For Redesign decks
          source =
            sectionSlide.isRedesign ||
            sectionSlide.source === 'redesign' ||
            sectionSlide.prefs?.source === 'redesign'
              ? mergeSlideConfig.mvp_assets_bucket
              : source;
          // For Synthesis Decks
          source =
            sectionSlide.isSynthesis ||
            sectionSlide.source === 'synthesis' ||
            sectionSlide.prefs?.source === 'synthesis'
              ? mergeSlideConfig.mvp_assets_bucket
              : source;
          // For Comply Decks
          source =
            sectionSlide.isComply ||
            sectionSlide.source === 'comply' ||
            sectionSlide.prefs?.source === 'comply'
              ? mergeSlideConfig.mvp_assets_bucket
              : source;
          // For Anoymized Decks
          source = sectionSlide.isAnonymized
            ? mergeSlideConfig.mvp_assets_bucket
            : source;
          // For Replaced slide
          // For backward compatibility
          source =
            sectionSlide.isReplaced ||
            sectionSlide.assetId?.startsWith('newImageReplaceOutput/') ||
            sectionSlide.assetId?.startsWith('/newImageReplaceOutput/')
              ? mergeSlideConfig.calyrex_bucket_name
              : source;
          // If replacement info is present, honour that
          source =
            sectionSlide?.isReplaced &&
            sectionSlide?.replacedInfo &&
            sectionSlide?.replacedInfo?.s3_bucket &&
            !sectionSlide.assetId?.includes('newImageReplaceOutput')
              ? sectionSlide.replacedInfo.s3_bucket
              : source;
          /* // For premium feature Decks
          source =
            sectionSlide.assetId?.indexOf('public/') !== -1 ||
            sectionSlide.assetId?.indexOf('private/') !== -1 ||
            sectionSlide.assetId?.indexOf('protected/') !== -1
              ? process.env.VUE_APP_MVP_ASSETS_BUCKET
              : source; */

          const mergeSlide = {
            slideId: fileName,
            source,
          };
          sectionSlides.push(mergeSlide);
        } else {
          // this is to add empty section when slide(file) is not avaialble for an idea
          // ** Required for merge slide service
          const mergeSlide = {
            slideId: 'NA',
            source: mergeSlideConfig.slides_bucket_name,
          };
          sectionSlides.push(mergeSlide);
        }
      }
      if (sectionSlides.length > 0) {
        const mergeSection = {
          sectionName,
          slides: sectionSlides,
        };
        sections.push(mergeSection);
      }
    }
  }
  // else slide level data
  else {
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].slideId && slides[i].slideId !== '') {
        if (
          slides[i].slideId?.indexOf('public/') !== -1 ||
          slides[i].slideId?.indexOf('private/') !== -1 ||
          slides[i].slideId?.indexOf('protected/') !== -1
        ) {
          // When slide has public. private, protected and is 'isOpUploaded'
          if (slides[i].isOPUploaded) {
            slides[i].source = mergeSlideConfig.external_service_bucket;
          } else {
            slides[i].source = process.env.VUE_APP_MVP_ASSETS_BUCKET;
          }
        } else if (slides[i].isReplaced) {
          slides[i].source = mergeSlideConfig.calyrex_bucket_name;
        } else if (
          slides[i].isUploaded ||
          slides[i].isUserUploaded ||
          slides[i].isGenerated ||
          slides[i].isSynthesis ||
          slides[i].isAnonymized ||
          slides[i].isRedesign ||
          slides[i].isComply
        ) {
          slides[i].source = mergeSlideConfig.mvp_assets_bucket;
        } else if (slides[i].isOPUploaded) {
          slides[i].source = mergeSlideConfig.external_service_bucket;
        } else {
          slides[i].source = mergeSlideConfig.slides_bucket_name;
        }
      } else {
        errors.push(`Slide id is missing for slide ${slides[i]}`);
      }
    }
  }

  if (errors.length > 0) {
    return {
      errors,
    };
  }

  result = {
    slides,
    outputPath,
    sections,
    outputFormat: data.outputFormat || '',
  };
  if (outputBucket !== '') {
    result.outputBucket = outputBucket;
  }

  return result;
}

export const getSlideSource = (sectionSlide) => {
  let source = mergeSlideConfig.slides_bucket_name;
  // FOR IOC Uploaded decks
  source = sectionSlide.isIOCUploaded
    ? mergeSlideConfig.mvp_assets_bucket
    : source;
  // FOR OP processed decks
  source = sectionSlide.isOPUploaded
    ? mergeSlideConfig.external_service_bucket
    : source;
  // FOR Sanitize upload decks
  source =
    sectionSlide.isUserUploaded ||
    sectionSlide.isUploaded ||
    sectionSlide.source === 'uploaded'
      ? mergeSlideConfig.mvp_assets_bucket
      : source;
  // For Generate decks
  source =
    sectionSlide.isGenerated || sectionSlide.source === 'generated'
      ? mergeSlideConfig.mvp_assets_bucket
      : source;
  // For Redesign decks
  source =
    sectionSlide.isRedesign || sectionSlide.source === 'redesign'
      ? mergeSlideConfig.mvp_assets_bucket
      : source;
  // For Synthesis Decks
  source =
    sectionSlide.isSynthesis || sectionSlide.source === 'synthesis'
      ? mergeSlideConfig.mvp_assets_bucket
      : source;
  // For Comply Decks
  source =
    sectionSlide.isComply || sectionSlide.source === 'comply'
      ? mergeSlideConfig.mvp_assets_bucket
      : source;
  // For Replaced slide
  // For backward compatibility
  source =
    sectionSlide.isReplaced ||
    sectionSlide.assetId?.startsWith('newImageReplaceOutput/') ||
    sectionSlide.assetId?.startsWith('/newImageReplaceOutput/')
      ? mergeSlideConfig.calyrex_bucket_name
      : source;
  // If replacement info is present, honour that
  source =
    sectionSlide?.isReplaced &&
    sectionSlide?.replacedInfo &&
    sectionSlide?.replacedInfo?.s3_bucket &&
    !sectionSlide.assetId?.includes('newImageReplaceOutput')
      ? sectionSlide.replacedInfo.s3_bucket
      : source;

  // For Anoymized Decks
  source = sectionSlide.isAnonymized
    ? mergeSlideConfig.mvp_assets_bucket
    : source;
  return source;
};

export const convertPPTToPng = async (bucket, path) => {
  const payload = {
    source: {
      s3_bucket: bucket,
      s3_path: path.substring(1),
    },
    target: {
      s3_bucket: bucket,
      s3_path: path.substring(1, path.lastIndexOf('/')),
      filename: path.substring(
        path.lastIndexOf('/') + 1,
        path.lastIndexOf('.'),
      ),
      output_formats: ['png'],
    },
  };
  const url = '/mergeslidesbeta/api/zipgensync';
  return await API_MERGE_SLIDE_SERVICE.post(url, payload).then(
    (res) => `/${res.output.data[0].thumbnail_png.s3_path}`,
  );
};

export const callMergeSlidesApi = async (payload) => {
  const path = '/mergeslidesbeta/api/mergeslides';
  const body = preparePayloadData(payload);
  if (body.errors) {
    throw new Error(body.errors);
  }
  const apiRes = await retryOperation(
    () => API_MERGE_SLIDE_SERVICE.post(path, body),
    1000,
    3,
  );
  // return {
  //   data: apiRes,
  // };
  return {
    data: `${payload.outputPath ? '' : 'merge_slide_output/'}${apiRes}`,
  };
};
